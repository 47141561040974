<template>
  <!-- <div class="waybill-list-wrap"> -->
  <div class="tab-list-container g-search-form-table">
    <search-form ref="form" :model="form" :count="11" label-width="auto" :collapse="true" @search="searchHandle" @reset="reset">
      <search-item label="" prop="customerUserName">
        <el-input v-model.trim="form.customerUserName" clearable placeholder="发单人姓名" />
      </search-item>
      <search-item label="" prop="customerName">
        <el-input v-model.trim="form.customerName" clearable placeholder="资质名称" />
      </search-item>
      <search-item label="" prop="loadAddrName">
        <el-input v-model.trim="form.loadAddrName" clearable placeholder="装货地址" />
      </search-item>
      <search-item label="" prop="unloadAddrName">
        <el-input v-model.trim="form.unloadAddrName" clearable placeholder="卸货地址" />
      </search-item>
      <template #hide>
        <search-item label="" prop="source">
          <el-select v-model="form.source" placeholder="货单类型" clearable>
            <el-option label="全部" :value="null" />
            <el-option v-for="item in $CONSTANT.goodsSourceMap.A" :key="item.value" :label="item.label" :value="+item.value" />
          </el-select>
        </search-item>
        <search-item label="" prop="goodsName">
          <el-input v-model.trim="form.goodsName" clearable placeholder="产品名称" />
        </search-item>
        <search-item label="" prop="goodsSerial">
          <el-input v-model.trim="form.goodsSerial" clearable placeholder="货单编号" />
        </search-item>
        <search-item label="" prop="type">
          <el-select
            v-model="form.type"
            placeholder="报车类型"
            clearable
          >
            <el-option label="全部" :value="null" />
            <el-option v-for="item in $CONSTANT.goodsTypeMap.A" :key="item.value" :label="item.label" :value="+item.value" />
          </el-select>
        </search-item>
        <search-item label="" prop="priority">
          <el-select v-model="form.priority" placeholder="优先级" clearable>
            <el-option label="全部" :value="null" />
            <el-option v-for="item in $CONSTANT.priorityArrayMap" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </search-item>
        <search-item label="" prop="state">
          <!-- searchState 0已关闭 8已报停 9运输完成 1进行中 multiple-->
          <el-select v-model="form.state" placeholder="货单状态" multiple clearable>
            <!-- <el-option label="全部" :value="null" /> -->
            <el-option v-for="item in $CONSTANT.goodsStateMap.SA" :key="item.value" :label="item.label" :value="+item.value" />
          </el-select>
        </search-item>
        <!-- 发单时间 -->
        <search-item label="" prop="createTime">
          <el-date-picker
            v-model="createTime"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
          />
        </search-item>
      </template>
    </search-form>
    <div class="tab-wrap">
      <div class="type-c">
        <div class="item" :class="{ active: activeTab === 'board' }" @click="toggleTab('board')">
          <img class="t1" src="@/assets/images/goodsOrder/board_active.svg">
          <img class="t2" src="@/assets/images/goodsOrder/board.svg">
        </div>
        <div class="item" :class="{ active: activeTab === 'list' }" @click="toggleTab('list')">
          <img class="t1" src="@/assets/images/goodsOrder/list_active.svg">
          <img class="t2" src="@/assets/images/goodsOrder/list.svg">
        </div>
      </div>
      <div class="action-wrap">
        <el-button v-if="activeTab === 'list'" @click="onExport">导出</el-button>
        <div v-else />
        <div class="jump-wrap">
          <span @click="toWayBill">查询结果范围运单</span>
          <span @click="toVehicleBill">查询结果范围车单</span>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="panel-wrapper">
      <div v-show="activeTab === 'board'" class="left-panel">
        <board-mode :goods-list="listData" />
      </div>
      <div v-show="activeTab === 'list'" class="right-panel">
        <list-mode :table-data="listData" />
      </div>
    </div>
    <div
      v-if="listData.length>0"
      class="pb10 text-align-r"
    >
      <el-pagination
        layout="sizes, prev, pager, next, jumper, total"
        :total="pagination.total"
        :page-size="pagination.size"
        :current-page="pagination.page"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
  </div>
</template>

<script>
import ListMode from './components/list';
import BoardMode from './components/board';
import goods from '@/api/transport/goods';
import { parseTime } from '@/utils';
export default {
  name: 'TransportGoods',
  components: { ListMode, BoardMode },
  filters: {
    parseTime,
  },
  data() {
    return {
      loading: false,
      form: {},
      activeTab: 'list',
      listData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      createTime: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async toggleTab(tab) {
      this.activeTab = tab;
    },
    timeChange(time) {
      if (!time) {
        this.form.beginCreateTime = null;
        this.form.endCreateTime = null;
      } else {
        this.form.beginCreateTime = time[0];
        this.form.endCreateTime = time[1] + 86400000 - 1;
      }
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.form,
        state: this.form.state ? this.form.state.length ? this.form.state.join(',') : null : null,
        size: this.pagination.size,
        current: this.pagination.page,
        // enable: 1, // 0未承接 1货源已承接
        post: this.$store.state.common.AUV_ROLE_CODE,
      };
      goods.orderGoodsTransList(params).then(res => {
        this.listData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    searchHandle() {
      this.pagination.page = 1;
      this.getList();
    },
    reset() {
      this.createTime = null;
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    toWayBill() {
      // 设置参数
      const params = {
        ...this.form,
        endCreateTime: this.form.endCreateTime - 86400000 + 1,
        createTime: this.createTime,
      };
      sessionStorage.setItem('goodListparams', JSON.stringify(params));
      this.$router.push('/transport/wayBill?fromGoodsList=true&removeAlive=true');
    },
    toVehicleBill() {
      const params = {
        ...this.form,
        endCreateTime: this.form.endCreateTime - 86400000 + 1,
        createTime: this.createTime,
      };
      sessionStorage.setItem('goodListparams', JSON.stringify(params));
      this.$router.push('/transport/vehicleBill?fromGoodsList=true&removeAlive=true');
    },
    // 前端导出excel
    onExport() {
      this.loading = true;
      const params = {
        ...this.form,
        state: this.form.state ? this.form.state.length ? this.form.state.join(',') : null : null,
        post: this.$store.state.common.AUV_ROLE_CODE,
      };
      goods.orderGoodsTransExport(params).then(res => {
        const listData = res || [];
        if (!listData) {
          this.$baseMessage('暂无数据可导出!', 'error');
          return;
        }
        const header = ['发单资质', '发单人姓名', '线路名称', '货主发单时间', '装货地址', '卸货地址', '货物名称', '货主运费价格（平台承接价格）', '信息费', '含税方式', '合理损耗(‰)', '货物单价', '计费方式', '结算方式', '平台上报时间', '车牌号', '司机姓名', '司机手机号', '司机身份证号', '装货吨数', '实际装货时间', '卸货吨数', '实际卸货时间', '损耗/吨', '车单状态', '货单编号', '车单编号'];
        const exportData = listData.map((item) => {
          return {
            '发单资质': item.goodCustomerName, // 发单资质
            '发单人姓名': item.goodCustomerUserName, // 发单人姓名
            '线路名称': item.lineName, // 线路名称
            '货主发单时间': item.goodOrderCreateTime, // 货主发单时间
            '装货地址': item.loadAddr, // 装货地址
            // '装货联系人': item.loadContacter + ' ' + item.loadContacterPhone, // 装货联系人 李四 1771910166542
            '卸货地址': item.unloadAddr, // 卸货地址
            // '卸货联系人':  item.unloadContacter + ' ' + item.unloadContacterPhone, // 卸货联系人 李四 1771910166542
            '货物名称': item.goodsName, // 货物名称
            '货主运费价格（平台承接价格）': item.price, // 货主运费价格（平台承接价格） 600元/吨
            '信息费': item.priceMiddle,
            '含税方式': item.needTax, // 含税方式
            '合理损耗(‰)': item.permitLose, // 合理损耗(‰)
            '货物单价': item.prodPrice, // 货物单价 元/吨
            '计费方式': item.billing, // 计费方式 按卸货吨数
            '结算方式': item.settlement, // 结算方式 卸货后30天、磅单原件
            '平台上报时间': item.reportTime, // 平台上报时间
            '车牌号': item.truckNo, // 车牌号
            '司机姓名': item.driverName, // 司机姓名
            '司机手机号': item.driverPhone, // 司机手机号
            '司机身份证号	': item.driverIdcode, // 司机身份证号
            // '运力标签': '黑货、白货', // 运力标签 黑货、白货
            '装货吨数': item.loadReceiptNum, // 装货吨数 33.92
            '实际装货时间': item.loadTime, // 实际装货时间 2024/8/13 18:36:25
            '卸货吨数': item.unloadReceiptNum, // 卸货吨数
            '实际卸货时间': item.unloadTime, // 实际卸货时间
            '损耗/吨': item.loss, // 损耗/吨 0
            '车单状态': item.state, // 车单状态 运输完成
            '货单编号': item.goodsSerial, // 货单编号
            // '运单编号': item.transSerial, // 运单编号
            '车单编号': item.carSerial, // 车单编号
          };
        });
        const data = exportData.map((product) => {
          return Object.values(product);
        });
        const exportDate = parseTime(new Date().getTime(), 'YYYYMMDDHHmmss');
        import('@/utils/export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: header,
            data,
            filename: '货单明细',
            merges: ['A1:AA1'],
            autoWidth: true,
            bookType: 'xlsx',
            needTitle: true,
            fileNameJoin: exportDate,
          });
          this.$baseMessage('导出成功！', 'success');
        });
      }).catch((error) => {
        console.log(error);
        this.$baseMessage('导出失败！', 'error');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  .jump-wrap{
    >span{
      color: $blue;
      cursor: pointer;
      font-size: 14px;
      margin-right: 10px;
    }
  }
}
</style>

<template>
  <div class="front-line-wrap">
    <div class="nav-title">
      <div v-for="(tab, index) in tabs" :key="index" :class="['cursor', { active: index === activeTabIndex }]">
        <span @click="changeTab(tab, index)">{{ tab.label }}</span>
      </div>
    </div>
    <div v-if="['PT005'].includes($store.state.common.AUV_ROLE_CODE)" class="chart-list">
      <Chart2 v-if="activeTabIndex===0" :chart-data="chartData" />
    </div>
    <div v-if="!['PT005'].includes($store.state.common.AUV_ROLE_CODE)" class="chart-list">
      <Chart1 v-if="activeTabIndex===0" :chart-data="chartData" />
      <Chart2 v-if="activeTabIndex===1" :chart-data="chartData" />
      <Chart3 v-if="activeTabIndex===2" :chart-data="chartData" />
    </div>
  </div>
</template>

<script>
import Chart1 from './charts/chart1';
import Chart2 from './charts/chart2';
import Chart3 from './charts/chart3';
export default {
  components: { Chart1, Chart2, Chart3 },
  data() {
    return {
      tabsPT005: [
        { label: '运输数据', value: 1 },
      ],
      tabsAll: [
        { label: '货源承运', value: 1 },
        { label: '运输数据', value: 2 },
        { label: '货主排行', value: 3 },
      ],
      activeTabIndex: 0,
      chartData: [],
    };
  },

  computed: {
    tabs() {
      return ['PT005'].includes(this.$store.state.common.AUV_ROLE_CODE) ? this.tabsPT005 : this.tabsAll;
    },
  },

  watch: {},

  created() {},

  mounted() {
  },

  methods: {
    changeTab(tab, index) {
      this.activeTabIndex = index;
      this.chartData = [];
    },
  },
};

</script>
<style lang='scss' scoped>
.front-line-wrap{
  .nav-title{
    display: flex;
    >div{
      margin-right: 30px;
      color: #A3A3A3;
    }
    .active{
      color: $blue;
    }
  }
}
</style>

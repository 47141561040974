<template>
  <div class="chart-wrap">
    <div id="main2" ref="chart" style="height: 180px;" />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { parseTime } from '@/utils';
// import Big from 'big.js';
export default {
  filters: {
    parseTime,
  },
  props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      chart: null,
      isFirst: true,
    };
  },
  watch: {
    chartData: {
      handler() {
        // console.log(123);
        this.drawChart();
      },
      deep: true,
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.chart.resize());
  },
  created() {
    // console.log(456);
    // this.drawChart();
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(789);
      this.drawChart();
    });
  },
  methods: {
    drawChart() {
      // const data = [{ a: 11111, b: 22222, c: 33333, d: 44444, e: 55555 }, { a: 22222, b: 22222, c: 33333, d: 44444, e: 55555 }, { a: 22555, b: 22222, c: 33333, d: 44444, e: 55555 }];
      // const dateData = data.map((d) => parseTime(Number(d.a)).substring(0, 10));
      // const auvData = data.map((d) => parseFloat(new Big(d.b).div(1000)));
      // const jcData = data.map((d) => parseFloat(new Big(d.c).div(1000)));
      // const rsData = data.map((d) => parseFloat(new Big(d.d).div(1000)));
      // const totalData = data.map((d) => parseFloat(new Big(d.e).div(1000)));
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     // saveAsImage: { show: true },
        //   },
        // },
        legend: {
          data: ['运输吨数', '运费金额'],
          // show: true,
          // type: 'plain',
          bottom: '-3%',
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '10%',
          top: '10%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
            axisPointer: {
              type: 'shadow',
            },
            // axisLine: { show: true, lineStyle: { color: '#D9D9D9' }},
          },
        ],
        yAxis: [
          {
            type: 'value',
            // name: '运输吨数',
            splitNumber: 5,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed', // 设置为虚线
              },
            },
          },
          {
            type: 'value',
            // name: '运费金额',
            splitNumber: 5,
            splitLine: {
              // 网格线
              show: false,
              // lineStyle: {
              //   type: 'dashed', // 设置为虚线
              // },
            },
          },
        ],
        series: [
          {
            name: '运输吨数',
            type: 'bar',
            barWidth: 36,
            tooltip: {
              valueFormatter: function(value) {
                return value + '吨';
              },
            },
            label: {
              show: true,
              position: 'top',
              color: '#5087EC',
            },
            itemStyle: {
            // 设置柱状图的颜色
              color: '#5087EC',
            },
            data: [
              20, 49, 70, 232, 256, 76.7, 135.6, 162.2, 32.6, 20.0, 64, 33,
            ],
          },
          {
            name: '运费金额',
            type: 'line',
            // stack: 'Total',
            label: {
              show: true,
              position: 'top',
              color: '#68BBC4',
            },
            // 设置折线颜色
            lineStyle: {
              color: '#68BBC4', // 这里设置为红色
              // width: 2, // 设置线宽
            },
            itemStyle: {
              color: '#68BBC4',
            },
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function(value) {
                return value + '元';
              },
            },
            data: [20, 22, 33, 45, 63, 102, 203, 234, 230, 165, 120, 62],
          },
        ],
      };
      // console.log(jcData, 'jcData');
      this.$nextTick(() => {
        // console.log(this.isFirst, 'this.isFirst');
        if (this.isFirst) {
          // this.chart = echarts.init(this.$refs.chart);
          this.chart = echarts.init(document.getElementById('main2'));
          this.chart.setOption(option, true);
          this.isFirst = false;
        } else {
          this.chart.setOption(option, true);
        }
        // this.chart.setOption(option, true);
        window.addEventListener('resize', () => {
          if (this.chart) {
            this.chart.resize();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

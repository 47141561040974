import clipboard from './module/clipboard';
// import hasRole from './permission/hasRole';
// import hasPermi from './permission/hasPermi';
// import btn from './permission/btn';

const install = function(Vue) {
  Vue.directive('copy', clipboard);
  // Vue.directive('hasRole', hasRole);
  // Vue.directive('hasPermi', hasPermi);
  // Vue.directive('btn', btn);
};

// if (window.Vue) {
//   window['copy'] = clipboard;
//   window['hasRole'] = hasRole;
//   window['hasPermi'] = hasPermi;
//   window['btn'] = btn;
//   Vue.use(install); // eslint-disable-line
// }

export default install;

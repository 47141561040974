<template>
  <div class="chart-wrap">
    <div id="main1" ref="chart" style="height: 180px;" />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { parseTime } from '@/utils';
// import Big from 'big.js';
export default {
  filters: {
    parseTime,
  },
  props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      chart: null,
      isFirst: true,
    };
  },
  watch: {
    chartData: {
      handler() {
        this.drawChart();
      },
      deep: true,
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.chart.resize());
  },
  created() {
    // this.drawChart();
  },
  mounted() {
    this.$nextTick(() => {
      this.drawChart();
    });
  },
  methods: {
    drawChart() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          data: ['货主', '承运商'],
          // show: true,
          // type: 'plain',
          // bottom: '-4%',
          top: '-2%',
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '14%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
            axisPointer: {
              type: 'shadow',
            },
            // axisLine: { show: true, lineStyle: { color: '#D9D9D9' }},
          },
        ],
        yAxis: [
          {
            type: 'value',
            // name: '运输吨数',
            splitNumber: 5,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed', // 设置为虚线
              },
            },
          },
          {
            type: 'value',
            // name: '运费金额',
            splitNumber: 5,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed', // 设置为虚线
              },
            },
          },
        ],
        series: [
          {
            name: '货主',
            type: 'bar', // 柱状图
            barWidth: 36,
            stack: 'total',
            label: {
              show: true,
            },
            emphasis: { // 折线图的高亮状态。
              focus: 'series', // 聚焦当前高亮的数据所在的系列的所有图形。
            },
            itemStyle: { normal: { color: '#5087EC' }},
            data: [320, 332, 301, 334, 390, 330, 320, 320, 332, 301, 334, 390, 330],
          },
          {
            name: '承运商',
            type: 'bar',
            barWidth: 36,
            stack: 'total',
            label: {
              show: true,
            },
            emphasis: {
              focus: 'series',
            },
            itemStyle: { normal: { color: '#68BBC4' }},
            data: [120, 132, 101, 134, 90, 230, 210, 302, 432, 201, 234, 392, 310],
          },
        ],
      };
      this.$nextTick(() => {
        // console.log(this.isFirst, 'this.isFirst');
        if (this.isFirst) {
          // this.chart = echarts.init(this.$refs.chart);
          this.chart = echarts.init(document.getElementById('main1'));
          this.chart.setOption(option, true);
          this.isFirst = false;
        } else {
          this.chart.setOption(option, true);
        }
        // this.chart.setOption(option, true);
        window.addEventListener('resize', () => {
          if (this.chart) {
            this.chart.resize();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

<template>
  <el-drawer
    :visible="drawerVisible"
    :title="drawerTitle"
    class="g-drawer"
    :wrapper-closable="false"
    size="600px"
    destroy-on-close
    @open="open"
    @close="close"
  >
    <div class="g-drawer-c">
      <!-- :disabled="formDisabled" -->
      <el-form
        ref="formRef"
        :model="drawerForm"
        :rules="drawerRules"
        label-position="right"
        label-width="125px"
      >
        <el-form-item label="业务资质类型" prop="type">
          <el-select
            v-model="drawerForm.type"
            placeholder="请选择"
            :disabled="drawerTypeDesc === 'edit'"
            @change="changeType"
          >
            <el-option
              v-for="item in typeSelectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <div v-if="drawerForm.type === '1' || drawerForm.type === '11' || drawerForm.type === '12'">
          <el-form-item label="企业名称" prop="name">
            <el-input
              v-model="drawerForm.name"
              placeholder="请输入"
              clearable
              :disabled="drawerTypeDesc === 'edit'"
            />
          </el-form-item>
          <div v-if="drawerTypeDesc !== 'edit'" class="name-tip">
            请填写完善的企业名称{{ userType===2?'，有助于货主识别':'' }}
          </div>
          <el-form-item label="企业简称" prop="simpleName">
            <el-input v-model="drawerForm.simpleName" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="企业简介" prop="remark">
            <el-input
              v-model="drawerForm.remark"
              placeholder="请输入"
              type="textarea"
              :rows="2"
              clearable
            />
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="drawerForm.phone" maxlength="11" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="联系地址" prop="addrRegion">
            <div class="flex">
              <el-cascader
                v-model="drawerForm.addrRegion"
                :options="areaTree"
                style="width: 250px"
              />
              <el-input v-model="drawerForm.addrDetail" placeholder="请输入详细地址" clearable />
            </div>
          </el-form-item>
          <el-divider />
          <el-form-item label="营业执照照片" prop="companyPic">
            <ImageUpload
              :model-value.sync="drawerForm.extMap.companyPic"
              placeholder="营业执照照片"
              :mode="formDisabled ? 'preview' : 'edit'"
            />
          </el-form-item>
          <el-form-item label="社会统一信用码" prop="companyCode">
            <el-input v-model="drawerForm.extMap.companyCode" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="有效期止" prop="companyExpireTime">
            <el-date-picker
              v-model="drawerForm.extMap.companyExpireTime"
              value-format="timestamp"
              type="date"
              placeholder="请选择"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <template v-if="drawerForm.type === '1' || drawerForm.type === '11'">
            <el-divider />
            <el-form-item label="道路运输许可证" prop="weihuaWayLicPic">
              <ImageUpload
                :model-value.sync="drawerForm.extMap.weihuaWayLicPic"
                placeholder="道路运输许可证"
                :mode="formDisabled ? 'preview' : 'edit'"
              />
            </el-form-item>
            <el-form-item label="有效期止" prop="weihuaWayLicExpireTime">
              <el-date-picker
                v-model="drawerForm.extMap.weihuaWayLicExpireTime"
                value-format="timestamp"
                type="date"
                placeholder="请选择"
                :picker-options="pickerOptions"
              />
            </el-form-item>
            <el-divider />
            <el-form-item label="危化品经营许可证" prop="weihuaBusinessLicPic">
              <ImageUpload
                :model-value.sync="drawerForm.extMap.weihuaBusinessLicPic"
                placeholder="危化品经营许可证"
                :mode="formDisabled ? 'preview' : 'edit'"
              />
            </el-form-item>
            <el-form-item label="有效期止" prop="weihuaBusinessLicExpireTime">
              <el-date-picker
                v-model="drawerForm.extMap.weihuaBusinessLicExpireTime"
                value-format="timestamp"
                type="date"
                placeholder="请选择"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </template>
        </div>
        <div v-if="drawerForm.type === '13'">
          <el-form-item label="归属企业名称" prop="dirverName">
            <el-input
              v-model="drawerForm.extMap.belongCompanyName"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="企业简称" prop="dirverSimpleName">
            <el-input v-model="drawerForm.simpleName" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="车牌号" prop="truckNo">
            <el-input v-model="drawerForm.truckNo" placeholder="请输入" :disabled="drawerTypeDesc === 'edit'" clearable />
          </el-form-item>
          <!-- <el-form-item label="联系电话" prop="phone">
            <el-input v-model="drawerForm.phone" maxlength="11" placeholder="请输入" clearable />
          </el-form-item> -->
          <el-divider />
          <el-form-item label="驾驶证" prop="driverLicPic">
            <ImageUpload
              :model-value.sync="drawerForm.extMap.driverLicPic"
              placeholder="驾驶证"
              :mode="formDisabled ? 'preview' : 'edit'"
              :limit="2"
            />
          </el-form-item>
          <el-form-item label="有效期止" prop="driverLicExpireTime">
            <el-date-picker
              v-model="drawerForm.extMap.driverLicExpireTime"
              value-format="timestamp"
              type="date"
              placeholder="请选择"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-divider />
          <el-form-item label="危化品从业资格证" prop="weihuaBusinessLicPic">
            <ImageUpload
              :model-value.sync="drawerForm.extMap.weihuaBusinessLicPic"
              placeholder="危化品从业资格证"
              :mode="formDisabled ? 'preview' : 'edit'"
            />
          </el-form-item>
          <el-form-item label="有效期止" prop="weihuaBusinessLicExpireTime">
            <el-date-picker
              v-model="drawerForm.extMap.weihuaBusinessLicExpireTime"
              value-format="timestamp"
              type="date"
              placeholder="请选择"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-divider />
          <el-form-item label="车辆行驶证" prop="truckLicPic">
            <ImageUpload
              :model-value.sync="drawerForm.extMap.truckLicPic"
              placeholder="车辆行驶证"
              :mode="formDisabled ? 'preview' : 'edit'"
            />
          </el-form-item>
          <el-form-item label="有效期止" prop="truckLicEffectiveTime">
            <el-date-picker
              v-model="drawerForm.extMap.truckLicEffectiveTime"
              value-format="timestamp"
              type="date"
              placeholder="请选择"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-divider />
          <el-form-item label="道路运输证" prop="weihuaWayLicPic">
            <ImageUpload
              :model-value.sync="drawerForm.extMap.weihuaWayLicPic"
              placeholder="道路运输证"
              :mode="formDisabled ? 'preview' : 'edit'"
            />
          </el-form-item>
          <el-form-item label="有效期止" prop="weihuaWayLicExpireTime">
            <el-date-picker
              v-model="drawerForm.extMap.weihuaWayLicExpireTime"
              value-format="timestamp"
              type="date"
              placeholder="请选择"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc === 'add'" type="primary" @click="submit">保存</el-button>
      <el-button v-if="drawerTypeDesc === 'edit'" type="primary" :disabled="[4].includes(drawerForm.state) && !['PT006'].includes($store.state.common.AUV_ROLE_CODE)" @click="submit">保存</el-button>
      <!-- 1未认证 2认证中  3已驳回 4已认证 -->
      <!-- 只有未认证、认证失败的可以提交审核，其他状态按钮置灰 -->
      <!-- 新增直接展示 -->
      <el-button
        v-if="drawerTypeDesc === 'add'"
        type="primary"
        @click="submitCheck"
      >提交审核</el-button>
      <el-button
        v-else
        type="primary"
        :disabled="[2, 4].includes(drawerForm.state)"
        @click="submitCheck"
      >提交审核</el-button>
    </div>
  </el-drawer>
</template>

<script>
import areaData from '@/utils/areaData';
import { validateTel, validateCarNO } from '@/utils/validateRule';
import {
  goodsUserQualificationAdd,
  goodsUserQualificationDetailCheck,
  goodsUserQualificationUpdate,
  transportUserQualificationAdd,
  transUserQualificationDetailCheck,
  transportUserQualificationUpdate,
} from '@/api/userManageMent';
// goodsUserQualificationDetail,
export default {
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    // 1货主 2承运商
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    currentData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      drawerTitleObj: {
        add: '业务资质认证申请',
        edit: '编辑业务资质',
        detail: '查看业务资质',
      },
      drawerTypeDesc: '',
      areaTree: [],
      userTypeLsitMap: {
        1: [{ label: '货主企业', value: '1' }],
        2: [
          { label: '物流企业', value: '11' },
          { label: '信息公司', value: '12' },
          { label: '个体运力', value: '13' },
        ],
        3: [{ label: '个体运力', value: '13' }],
      },
      drawerForm: {
        type: '', // 业务资质类型  货主企业1  物流企业11   信息公司12  个体运力13
        // name: '', //企业名称
        // simpleName: '', //企业简称
        // remark: '', //企业简介add
        // phone: '', //联系电话add
        // addrRegion: [], //联系地址add
        // addrDetail: '', //详细地址add
        extMap: {
          // settlementType: '3', //结算方式add
          // settlementCycle: '', //天
          // settlementNeedReceipt: false, //是否见磅单原件
          // companyPic: '', //营业执照图片
          // companyCode: '', //统一社会信用代码
          // companyExpireTime: '', //营业执照有效期止
          // weihuaWayLicPic: '', //道路运输许可证
          // weihuaWayLicExpireTime: '', //道路运输许可证有效期止
          // weihuaBusinessLicPic: '', //危化品经营许可证
          // weihuaBusinessLicExpireTime: '' //危化品经营许可证有效期止
          // truckLicPic: '', //车辆行驶证
          // truckLicEffectiveTime: '' //车辆行驶证有效期止
          // driverLicPic: '', //驾驶证
          // driverLicExpireTime: '' //驾驶证有效期止
        },
      },
      drawerRules: {
        name: [{ required: true, message: ' ', trigger: 'change' }],
        simpleName: [{ required: true, message: '请输入企业简称', trigger: 'change' }],
        phone: [{ required: false, validator: validateTel }],
        truckNo: [
          { required: true, message: '请输入车牌号', trigger: 'change' },
          { validator: validateCarNO, trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    formDisabled() {
      return this.drawerTypeDesc === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
    typeSelectList() {
      return this.userTypeLsitMap[this.userType];
    },
  },
  methods: {
    open() {
      this.areaTree = areaData.pcaTextArr;
      this.drawerTypeDesc = this.drawerType;
      if (this.drawerType !== 'add') {
        this.getDetail();
      } else {
        this.drawerForm.type = this.typeSelectList.length ? this.typeSelectList[0]?.value : '';
        // 非司机（个体运力） 需回显结算方式
        if (this.drawerForm.type !== '13') {
          this.drawerForm.extMap.settlementType = '3'; // 结算方式
        }
      }
    },
    close() {
      this.drawerForm = {
        type: '', // 业务资质类型  货主企业1  物流企业11   信息公司12  个体运力13
        extMap: {},
      };
      this.$refs.formRef.resetFields();
      this.$emit('update:drawerVisible', false);
    },
    changeType(e) {
      this.$refs.formRef.resetFields();
      this.drawerForm = {
        type: e,
        extMap: {},
      };
      // 非司机（个体运力） 需回显结算方式默认开票后
      if (e !== '13' && this.drawerTypeDesc === 'add') {
        this.drawerForm.extMap.settlementType = '3'; // 结算方式
      }
    },
    getDetail() {
      const res = JSON.parse(JSON.stringify(this.currentData));
      if (res) {
        if (res.type !== '13') {
          res.addrRegion = res.addrRegion ? res.addrRegion.split(',') : [];
          if (res.extMap) {
            // res.extMap.settlementNeedReceipt = res.extMap.settlementNeedReceipt === 'true';
            // (res.extMap.companyExpireTime = +res.extMap.companyExpireTime || '');
            // res.extMap.weihuaBusinessLicExpireTime = +res.extMap.weihuaBusinessLicExpireTime || '';
            // res.extMap.weihuaWayLicExpireTime = +res.extMap.weihuaWayLicExpireTime || '';
          } else {
            res.extMap = {};
          }
          this.drawerForm = res || {};
        } else {
          // if (res.extMap) {
          //   res.extMap.weihuaBusinessLicExpireTime = +res.extMap.weihuaBusinessLicExpireTime || '';
          //   res.extMap.truckLicEffectiveTime = +res.extMap.truckLicEffectiveTime || '';
          //   res.extMap.weihuaWayLicExpireTime = +res.extMap.weihuaWayLicExpireTime || '';
          // } else {
          //   res.extMap = {};
          // }
          this.drawerForm = res || {};
        }
      }
    },
    submit() {
      // type 获取type 分别对应api提交
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          const newDrawerForm = JSON.parse(JSON.stringify(this.drawerForm));
          if (newDrawerForm && this.drawerForm.type !== '13') {
            newDrawerForm.addrRegion = newDrawerForm.addrRegion
              ? newDrawerForm.addrRegion.join(',')
              : ''; // 区域地址
          }
          if (this.drawerForm.type === '13') {
            // 此处extMap空对象无值时 预留extMap值原因 extMap为空对象传给后台 后台详情接口不返回该字段会导致回显错误
            if (!newDrawerForm.extMap.driverLicPic) {
              newDrawerForm.extMap.driverLicPic = '';
            }
          }
          const addParamsOwner = {
            ...newDrawerForm,
            user: {
              userId: this.userInfo.userId,
            },
          };
          const addParamsCarrier = {
            ...newDrawerForm,
            // var2: this.userInfo.userId
            user: {
              userId: this.userInfo.userId,
            },
          };
          const editParamsOwner = {
            ...newDrawerForm,
            user: {
              userId: this.userInfo.userId,
            },
          };
          const editParamsCarrier = {
            ...newDrawerForm,
            // var2: this.userInfo.userId
            user: {
              userId: this.userInfo.userId,
            },
          };
          const addParamsMap = {
            1: addParamsOwner,
            2: addParamsCarrier,
          };
          const editParamsMap = {
            1: editParamsOwner,
            2: editParamsCarrier,
          };
          const params =
          this.drawerTypeDesc === 'edit'
            ? editParamsMap[this.userType]
            : addParamsMap[this.userType];
          // return
          if (this.drawerTypeDesc === 'add') {
            try {
              const apiMap = {
                1: goodsUserQualificationAdd,
                2: transportUserQualificationAdd,
              };
              const res = await apiMap[this.userType](params);
              if (res) {
                this.$baseMessage('保存成功', 'success');
                this.$emit('drawerSubmit');
                this.close();
              } else {
                this.$baseMessage('保存失败', 'error');
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              const apiMap = {
                1: goodsUserQualificationUpdate,
                2: transportUserQualificationUpdate,
              };
              const res = await apiMap[this.userType](params);
              if (res) {
                this.$baseMessage('编辑成功', 'success');
                this.$emit('drawerSubmit');
                this.close();
              } else {
                this.$baseMessage('编辑失败', 'error');
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      });
    },
    submitCheck() {
      // type 获取type 分别对应api提交
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          const newDrawerForm = JSON.parse(JSON.stringify(this.drawerForm));
          if (newDrawerForm && this.drawerForm.type !== '13') {
            newDrawerForm.addrRegion = newDrawerForm.addrRegion
              ? newDrawerForm.addrRegion.join(',')
              : ''; // 区域地址
          }
          const addParamsOwner = {
            ...newDrawerForm,
            user: {
              userId: this.userInfo.userId,
              userName: this.userInfo.userName,
              userPhone: this.userInfo.userPhone,
            },
          };
          // const addParamsCarrier = {
          //   ...newDrawerForm,
          //   var2: this.userInfo.userId
          // }

          const addParamsMap = {
            1: addParamsOwner,
            2: addParamsOwner,
          };
          const params = addParamsMap[this.userType];
          // return
          try {
            const apiMap = {
              1: goodsUserQualificationDetailCheck,
              2: transUserQualificationDetailCheck,
            };
            const res = await apiMap[this.userType](params);
            if (res) {
              this.$baseMessage('提交审核成功', 'success');
              this.$emit('drawerSubmit');
              this.close();
            } else {
              this.$baseMessage('提交审核失败', 'error');
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
  },
};

</script>
<style lang="scss" scoped>
.name-tip{
  color: #F56C6C;
  font-size: 12px;
  margin: -20px 0 3px 0;
  margin-left: 125px;
}
</style>

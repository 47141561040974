<template>
  <div class="map-container">
    <fullscreen :fullscreen.sync="fullscreen" class="fullMap-wrap">
      <div class="fullMap">
        <TMap ref="TianMap" map-id="mapCarLog" class="map" @load="mapLoadHandle" @markerClick="markerClickHandle" />
        <div class="left-card">
          <div class="flex">
            <el-button class="car-button" icon="el-icon-search" type="primary" @click="searchMark" />
            <el-input v-model="carNumber" placeholder="请输入车牌号" clearable @keyup.enter.native="searchMark" />
          </div>
          <div>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              @change="timeChange"
            />
          </div>
        </div>
        <RightPanel
          v-if="showDetail"
          class="right-card"
          :car-detail="carDetailInfo"
          :park-array="parkArray"
        />
      </div>
    </fullscreen>
  </div>
</template>

<script>
import { getCarTrack, getCarRouterPath } from '@/api/transport/map/map';
import RightPanel from './carLog/rightPanel';
import dayjs from 'dayjs';
import TMap from '@/components/TMap';
let map = null;
let T = null;
export default {
  components: { TMap, RightPanel },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 计算四个月前的日期
          const fourMonthsAgo = new Date();
          fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);
          // 阻止选择早于四个月前的日期
          const isBeforeFourMonths = time.getTime() < fourMonthsAgo.getTime();
          // 阻止选择当前时间之后的日期
          const isAfterNow = time.getTime() > new Date().getTime();
          return isBeforeFourMonths || isAfterNow;
        },
      },
      timer: null, // 定时器状态
      marker: null,
      fullscreen: false,
      carNumber: '', // 车牌号
      createTime: [],
      beginTime: 0, // 开始时间
      endTime: 0, // 结束时间
      carDetailInfo: {}, // 车辆基础信息
      lnglatList: [], // 轨迹不加装卸货点经纬度集合
      parkArray: [], // 停车数据（告警数据）
      showDetail: false, // 是否展示右侧卡片
    };
  },
  created() {
    this.toggleCollapse();
  },
  destroyed() {
    this.$store.commit('common/setCollapse', false);
    if (this.$refs.TianMap) {
      this.$refs.TianMap.unsubscribeCars();
    }
  },
  methods: {
    toggleCollapse() {
      setTimeout(() => {
        this.$store.commit('common/setCollapse', true);
      });
    },
    // 地图加载完成
    mapLoadHandle(data) {
      map = data.map;
      T = data.T;
      this.autoSearchHandle();
    },
    async markerClickHandle(e) {
      this.showDetail = true;
      const addressRes = await this.$refs.TianMap.getAddress([e.lgt / 1000000.0, e.lat / 1000000.0]);
      let addressDesc;
      if (+addressRes.status === 0 && addressRes.result) {
        addressDesc = addressRes.result.formatted_address || '';
      }
      this.carDetailInfo = {
        vno: e.carNo,
        customerName: '', // 暂无
        spd: e.speed,
        residueDistance: '', // 暂无
        residueDriverTime: '', // 暂无
        adr: addressDesc,
      };
    },
    // 自动搜索
    autoSearchHandle() {
      const { carNumber } = this.$route.query;
      if (carNumber) {
        this.carNumber = carNumber;
        this.beginTime = dayjs(new Date().getTime() - 72 * 60 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss');
        this.endTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.createTime = [this.beginTime, this.endTime];
        this.searchMark();
      }
      // 订阅车辆
      // this.$refs.TianMap.subscribeCars([
      //   '冀EU4150',
      //   '苏AE9356',
      //   '冀A535TE',
      //   '苏GX2558',
      //   '苏AP2729',
      //   '苏AE2431',
      //   '苏AE1485',
      //   '苏A9D535',
      //   '苏A9A220',
      //   '冀EU4490',
      //   '冀A5641V',
      //   '冀AKX696',
      //   '苏GJ3526',
      //   '冀EU4088',
      //   '苏AN2739',
      //   '冀DW1067',
      //   '冀DS1089',
      //   '苏AN3636',
      //   '冀EV5386',
      //   '苏GL5177',
      //   '苏G1335E',
      //   '冀EV5190',
      //   '苏AE1485',
      //   '苏A1B103',
      //   '苏A1F616',
      //   '苏A2B898',
      // ]);
    },
    // 搜索车+时间
    searchMark() {
      this.carDetailInfo = {};
      this.parkArray = [];
      this.showDetail = false;
      if (!this.carNumber) {
        this.$baseMessage('请输入车牌号!', 'error');
        return;
      }
      if (!this.createTime.length) {
        this.$baseMessage('请选择开始结束时间!', 'error');
        return;
      }
      this.$refs.TianMap.unsubscribeCars();
      this.$refs.TianMap.subscribeCars([this.carNumber]);
      this.getCarLocation();
      this.getCarTrackAndPark();
    },
    // 选择时间
    timeChange(e) {
      this.beginTime = e?.[0] || null;
      this.endTime = e?.[1] || null;
      if (e && e.length === 2) {
        const [startDate, endDate] = e;
        // 检查时间区间是否为72小时内
        const timeDiff = new Date(endDate).getTime() - new Date(startDate).getTime();
        if (timeDiff > 72 * 60 * 60 * 1000) {
          this.$baseMessage('请选择72小时内的时间区间!', 'error');
          this.createTime = [];
        }
      }
    },
    // 获取车辆当前位置信息
    getCarLocation() {
      const params = {
        vnos: this.carNumber + '_2',
      };
      getCarTrack(params).then((res) => {
        this.carDetailInfo = res?.firstVcl || {};
      });
    },
    // 获取车辆行驶轨迹和告警信息
    getCarTrackAndPark() {
      const params = {
        vclN: this.carNumber, // 车牌号
        vco: 2, // 车牌颜色 2黄色
        qryBtm: this.beginTime, // 开始时间
        qryEtm: this.endTime, // 结束时间
      };
      getCarRouterPath(params).then(res => {
        if (res.parkArray.length) {
          this.addAddressToObjects(res.parkArray).then(newParkArray => {
            this.parkArray = newParkArray || [];
          }).catch(error => console.error(error));
        } else {
          this.parkArray = [];
        }
        this.lnglatList = res?.trackArray || [];
        // 划轨迹
        this.drawLine();
      });
    },
    // 获取地址详情
    async addAddressToObjects(array) {
      const promises = array.map(async(obj) => {
        // const address = await this.getAddress([obj.parkLon / 600000.0, obj.parkLat / 600000.0]);
        const address = await this.$refs.TianMap.getAddress([obj.parkLon / 1000000.0, obj.parkLat / 1000000.0]);
        return { ...obj, address };
      });
      const updatedArray = await Promise.all(promises);
      return updatedArray;
    },
    drawLine() {
      const lnglatList = this.lnglatList || [];
      // const lnglatList = [
      //   { lon: 118334046, lat: 29717900 },
      //   { lon: 119020595, lat: 31614598 },
      //   { lon: 119120595, lat: 31714598 },
      //   { lon: 119620595, lat: 32714598 },
      // ];
      const PolylineList = lnglatList.map((item) => {
        return {
          longitude: item.lon / 1000000.0,
          latitude: item.lat / 1000000.0,
        };
      });
      this.$refs.TianMap.drawPolyLine(PolylineList);
      const start = PolylineList[0];
      const end = PolylineList.at(-1);
      map.setViewport([new T.LngLat(start.longitude, start.latitude), new T.LngLat(end.longitude, end.latitude)]);
    },
  },
};
</script>
<style lang="scss" scoped>
.map-container{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.fullMap-wrap {
  // position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.fullMap {
  position: relative;
  width: 100%;
  height: 100%;
  // height: 610px;
  // height:100vh;
  padding: 0;
  margin: 0;
}
.left-card{
  position: absolute;
  top: 10px;
  left: 12px;
  display: flex;
  width:580px;
  z-index: 999;
  ::v-deep.el-input-group__append,
  .el-input-group__prepend {
    background-color: #409eff;
    color: #fff;
  }
  ::v-deep {
    .el-button,
    .el-input__inner {
      border-radius: 0;
    }
  }
}

.map {
  width: 100%;
  height: 100%;
  max-height: 100%;
}
.map-full {
  color: #4385ff;
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 50px;
  z-index: 1;
  cursor: pointer;
}

.full-img {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.all-full {
  top: -44px;
  right: 40px;
  &:hover {
    opacity: 0.9;
  }
}
</style>

<template>
  <div class="map-container">
    <fullscreen :fullscreen.sync="fullscreen" class="fullMap">
      <div style="height: 100%; width: 100%">
        <TMap ref="TianMap" class="map" @load="mapLoadHandle" @markerClick="markerClickHandle" />
        <LeftCarrier class="left-card" :current-goods-order="currentGoodsOrder" :all-car-data="allCarList" @leftSearchMark="leftSearchMark" />
        <RightPanel
          class="right-card"
          :current-goods-order="currentGoodsOrder"
          :goods-order-list="goodsOrderList"
          :all-car-data="allCarList"
          @rightShowCarLog="rightShowCarLog"
          @selectGoodsOrder="selectGoodsOrder"
        />
      </div>
    </fullscreen>
    <el-dialog title="车单履约详情" :visible.sync="dialogVisible" class="map-dialog-wrap" width="90%" top="0" @close="closeDialog">
      <CarLog ref="carLog" :car-info="currentClickCarInfo" />
    </el-dialog>
  </div>
</template>

<script>
import LeftCarrier from './components/leftCarrier';
import RightPanel from './components/rightPanel';
import CarLog from './carLog/index';
import { shareGoodsOrderDetail } from '@/api/transport/monitor/transportMap';
import { parseTime } from '@/utils';
import { mapGetters } from 'vuex';
import TMap from '@/components/TMap';
// let map = null;
// let T = null;
export default {
  name: 'BgMap',
  filters: {
    parseTime,
  },
  components: { TMap, LeftCarrier, RightPanel, CarLog },
  data() {
    return {
      goodsOrderList: [], // 货单列表
      currentGoodsOrder: {}, // 当前的货单数据
      allCarList: {}, // 当前货单下所有车单列表数据
      currentClickCarInfo: {}, // 当前点击得车单（辆）信息
      dialogVisible: false, // 车单履约弹窗
      fullscreen: false,
      carMarkerMap: {}, // 所有接收到得车辆信息

      mockData: {},
      mockIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      socket: 'carMonitor/socket',
      currentCarData: 'carMonitor/currentCar',
    }),
  },
  watch: {
    // mockData currentCarData
    currentCarData(data) {
      const carsList = Object.values(this.allCarList).reduce((acc, val) => acc.concat(val), []) || [];
      const car = carsList.find(car => car.truckNo === data.a);
      const { a, b, c, d, f } = data;
      // 处理数据
      if (!this.carMarkerMap[a]) {
        const carMarkData = {
          carNo: a,
          lgt: b,
          lat: c,
          speed: d,
          direction: f,
          state: car?.state || 3,
        };
        this.carMarkerMap[a] = {};
        this.$refs.TianMap.addVehicleMarker(carMarkData, { lon: carMarkData.lgt / 1000000, lat: carMarkData.lat / 1000000 });
        this.carMarkerMap[a] = { b, c, d, f };
      } else {
        const carMarkData = {
          carNo: a,
          lgt: b,
          lat: c,
          speed: d,
          direction: f,
          state: car?.state || 3,
        };
        this.carMarkerMap[a] = { b, c, d, f };
        this.$refs.TianMap.updateVehiclePosition(carMarkData, { lon: carMarkData.lgt / 1000000, lat: carMarkData.lat / 1000000 });
      }
    },
  },
  created() {
    this.toggleCollapse();
    // this.getMockData();
  },
  destroyed() {
    this.$store.commit('common/setCollapse', false);
    this.$store.dispatch('carMonitor/CLOSE_SOCKED');
    if (this.$refs.TianMap) {
      this.$refs.TianMap.removeMarkAndPolyLine();
    }
  },
  mounted() {
    this.socketInitAndOpen();
  },
  methods: {
    getMockData() {
      setInterval(() => {
        const mockData = {
          // 天集-腾飞1.5
          // 腾飞-医院1.8
          // 医院-中海1.6
          // 天集-中海3.9
          // 天集江来
          0: {
            a: '苏AE5453',
            b: 118644019,
            c: 32033591,
            d: 79,
            f: 154,
            g: 1,
          },
          // 腾飞大厦
          1: {
            a: '苏AE5453',
            b: 118649818,
            c: 32044172,
            d: 70,
            f: 154,
            g: 1,
          },
          // 医院
          2: {
            a: '苏AE5453',
            b: 118642623,
            c: 32054173,
            d: 75,
            f: 154,
            g: 1,
          },
          // 中海
          3: {
            a: '苏AE5453',
            b: 118631798,
            c: 32053461,
            d: 75,
            f: 154,
            g: 1,
          },
          4: {
            a: '苏A2F156',
            b: 118916587,
            c: 32162128,
            d: 56,
            f: 144,
            g: 1,
          },
          5: {
            a: '苏A2F156',
            b: 118915497,
            c: 32156860,
            d: 46,
            f: 134,
            g: 1,
          },
          6: {
            a: '苏AE5453',
            b: 118915497,
            c: 32152690,
            d: 66,
            f: 134,
            g: 1,
          },
          7: {
            a: '苏AE5453',
            b: 118915591,
            c: 32153158,
            d: 96,
            f: 134,
            g: 1,
          },
          8: {
            a: '苏A2F156',
            b: 118926497,
            c: 32158960,
            d: 96,
            f: 134,
            g: 1,
          },
        };
        if (this.mockIndex < 4) {
          this.mockData = mockData[this.mockIndex];
          this.mockIndex = this.mockIndex + 1;
        }
      }, 3000);
    },
    toggleCollapse() {
      setTimeout(() => {
        this.$store.commit('common/setCollapse', true);
      });
    },
    // 全屏
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
    socketInitAndOpen() {
      const res = {
        d: '15139fef1515149921bc8c4803f2',
      };
      if (res.d) {
        const wsUrls = 'ws://49.4.68.207:9400/ws';
        this.$store.dispatch('carMonitor/SOCKET_INIT', wsUrls);
        this.$store.dispatch('carMonitor/SOCKET_OPEN', { msg: res.d, type: [0, 1, 0] });
      } else {
        this.$message.error('websocket的token获取失败');
      }
    },
    // 地图加载完成
    mapLoadHandle(data) {
      // map = data.map;
      // T = data.T;
      this.getGoodsOrderDetail();
    },
    // 获取货单详情
    getGoodsOrderDetail(gId) {
      const params = {
        userId: +this.$store.state.common.AUV_USER_ID,
        gId, // 货单id 不传默认查货单列表第一条得详情
      };
      shareGoodsOrderDetail(params).then(res => {
        this.currentGoodsOrder = res || {};
        // 地图绘制起始点
        this.drawStartAndEnd(res.loadAddrPoint, res.unloadAddrPoint);
        // ws推送车辆 获取车辆经纬度信息
        const { extTypeMap = {}} = res;
        if (extTypeMap.carList) {
          this.allCarList = extTypeMap.carList;
          const cars = Object.values(this.allCarList).reduce((acc, val) => acc.concat(val), []).map(d => d.truckNo) || [];
          // const cars = ['苏AE2842', '苏AE5453', '苏A9B047', '苏A2B898', '苏A2F156', '苏AE5453', '苏AE4856'];
          if (cars.length) {
            this.sendSocket(cars);
          }
        }
      });
    },
    // 根据起始点规划路线并打点起点终点
    drawStartAndEnd(startLnglat, endLnglat) {
      if (startLnglat && endLnglat) {
        const lnglatList = [
          { longitude: +startLnglat.split(',')[0], latitude: +startLnglat.split(',')[1] },
          { longitude: +endLnglat.split(',')[0], latitude: +endLnglat.split(',')[1] },
        ];
        // 根据装卸货经纬度 绘制起始点导航规划线路
        this.$refs.TianMap.drawStartAndEndHandle(lnglatList);
      }
    },
    // marker点击
    async markerClickHandle(e) {
      const carInfo = this.getCarInfo(e.carNo);
      this.currentClickCarInfo = {
        ...e,
        ...carInfo,
      };
      this.dialogVisible = true;
    },
    // 所有车牌号录入进行ws通信
    sendSocket(cars) {
      const carObj = {
        a: cars,
      };
      this.$store.dispatch('carMonitor/SOCKET_SEND', { msg: carObj, type: [0, 2, 0] });
    },
    // 根据车牌号回查车辆id
    // getCarId(carNo) {
    //   console.log(carNo, 'carNo');
    //   const currentCar = this.allCarList.find((item) => {
    //     return item.b === carNo;
    //   });
    //   console.log(currentCar, 'currentCar');
    //   return currentCar.c;
    // },
    // 根据车牌号回查车单（辆）信息
    getCarInfo(carNo) {
      const cars = Object.values(this.allCarList).reduce((acc, val) => acc.concat(val), []);
      const currentCar = cars.find((item) => {
        return item.truckNo === carNo;
      });
      return currentCar;
    },
    // 左侧选择车辆后传入车牌号
    leftSearchMark(inputSearch) {
      const clickMarkerInfo = this.carMarkerMap[inputSearch];
      if (clickMarkerInfo?.b && clickMarkerInfo?.c) {
        const lnglat = {
          longitude: +clickMarkerInfo.b / 1000000,
          latitude: +clickMarkerInfo.c / 1000000,
        };
        this.$refs.TianMap.setSinglePointViewPort(lnglat);
      }
    },
    // 右侧点击分类状态 车牌号后 定位到具体车牌
    rightShowCarLog(data) {
      const clickMarkerInfo = this.carMarkerMap[data.truckNo];
      if (clickMarkerInfo?.b && clickMarkerInfo?.c) {
        const lnglat = {
          longitude: +clickMarkerInfo.b / 1000000,
          latitude: +clickMarkerInfo.c / 1000000,
        };
        this.$refs.TianMap.setSinglePointViewPort(lnglat);
      }
    },
    // 右侧卡片选择货单事件
    selectGoodsOrder(data) {
      if (this.$refs.carLog) {
        this.$refs.carLog.removeMarkAndPolyLine();
      }
      if (data.id !== this.currentGoodsOrder.id) {
        this.$refs.TianMap.removeMarkAndPolyLine();
        // 获取最新的货单 并绘制
        // this.$store.dispatch('carMonitor/CLOSE_SOCKED');
        this.carMarkerMap = {};
        this.getGoodsOrderDetail(data.id);
      }
    },
    closeDialog() {
      this.$refs.carLog.removeMarkAndPolyLine();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-button,
  .el-input__inner {
    border-radius: 0;
  }
}
.map-container {
  height: calc(100vh - 130px);
  .fullMap {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .map {
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
  .left-card{
    // width: 350px;
    position: absolute;
    top: 10px;
    left: 12px;
    // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  .right-card{
    // width: 350px;
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 999;
    // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  }
}
.map-dialog-wrap {
  transform: translateZ(101px) !important;
  display: flex;
  flex-direction: column;
  ::v-deep .el-dialog {
    margin:auto!important;
  }
}
</style>

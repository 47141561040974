<template>
  <div class="right-panel-wrap">
    <div class="car-order-detail-wrap">
      <div class="card-wrap">
        <div class="address-wrap">
          <div class="left-address">
            <div class="address">
              <div>{{ carDetail.loadAddrName || "--" }}</div>
              <div>装 {{ carDetail.loadEarlyTime | parseTime("MM-DD HH:mm") }}</div>
            </div>
            <div class="arrow-wrap">
              <div class="product">{{ carDetail.goodsName || "--" }}</div>
              <img src="@/assets/images/monitor/arrow-right.svg">
              <!-- <div class="weight">{{ $parseNumber(carDetail.goodsWeight,1000) }}吨</div> -->
            </div>
            <div class="address">
              <div>{{ carDetail.unloadAddrName || "--" }}</div>
              <div>卸 {{ carDetail.unloadEarlyTime | parseTime("MM-DD HH:mm") }}</div>
            </div>
          </div>
        </div>
        <div class="detail-info-wrap">
          <div class="box-title">
            <div class="left">{{ carOrderPerformanceStateMap[carDetail.state] }}</div>
            <div class="right">
              <span>车单号：{{ carDetail.carSerial || "--" }}</span>
              <img v-copy="carDetail.carSerial" src="@/assets/images/monitor/copy.svg">
            </div>
          </div>
          <div class="box-card-wrap">
            <div>
              <div>
                <span>当前车速 </span>
                <span class="value" :style="{ color: +dialogMarkerData.speed > 80 ? '#EF5063' : '' }">{{ dialogMarkerData.speed||'-' }}km/h</span>
              </div>
              <div>
                <span>剩余行驶 </span>
                <span class="value">{{ dialogMarkerData.residueDistance||'-' }}km</span>
                <!-- <span class="value">{{ ![9, '-1', 12, 13].includes(carDetail.state)?dialogMarkerData.residueDistance||'-':'-' }}km</span> -->
              </div>
              <div>
                <span>预计时长 </span>
                <span class="value">{{ dialogMarkerData.residueDriverTime||'-' }}</span>
              </div>
            </div>
            <div>
              <div>
                <span>当前位置：</span>
                <span>{{ dialogMarkerData.address||'-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-wrap">
        <div class="car-info-detail-wrap">
          <div class="car-info">
            <div class="top-car-che">{{ carDetail.truckNo || "--" }}</div>
            <div class="top-car-che">{{ carDetail.guaNo || "--" }}</div>
            <div class="top-car-type" :style="{ color: returnColor(), background: returnBackground() }">
              <div class="status-text">{{ $CONSTANT.carOrderStateMap.O[+carDetail.state] || "--" }}</div>
            </div>
          </div>
          <div class="people-company-info">
            <div class="compamy-wrap">
              <i class="iconfont icon-building-3-line" />
              <span>{{ carDetail.customerName || "-" }}</span>
            </div>
            <div class="people-wrap">
              <div>
                <i class="iconfont icon-driver-line" />
                <span>{{ carDetail.driverName || "--" }} {{ carDetail.driverPhone || "--" }}</span>
              </div>
              <div>
                <i class="iconfont icon-supercargo-line" />
                <span>{{ carDetail.escortName || "--" }} {{ carDetail.escortPhone|| "--" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-wrap">
        <div class="time-line-wrap">
          <div class="km">
            <i class="iconfont icon-steering-line" />
            <div>
              <div>
                <span>{{ dialogMarkerData.driverDistance||'-' }}km</span>
                <span class="label">实际行驶</span>
              </div>
              <div>
                <span>{{ dialogMarkerData.driverTime }}</span>
              </div>
            </div>
            <!-- <div>
              <div>
                <span :style="{ color: +99 > 80 ? '#EF5063' : '' }">{{ 100 }}km/h</span>
                <span class="label">当前车速</span>
              </div>
              <div>
                <span>{{ allDistence || "--" }}km {{ allTimeH }}h{{ allTimeM }}m</span>
                <span class="label">剩余里程</span>
              </div>
            </div> -->
          </div>
          <div class="w-line-show">
            <div v-for="(item, i) in processNodeDataNew" :key="i" :class="['item',{'active-item':item.nodeTime}]">
              <div class="icon-wrap">
                <i class="el-icon-check" />
              </div>
              <div class="flexc right-wrap">
                <div class="line-item-top flex-b-c">
                  <div>
                    <span class="node-text">{{ typeMap[item.nodeType] }}</span>
                    <span class="dun-text">{{ [7,9].includes(+item.nodeType)?`${$parseNumber(item.ext1,1000)}吨`:'' }}</span>
                  </div>
                  <span class="w-time">{{ item.nodeTime | parseTime }}</span>
                </div>
                <div class="line-item-bottom">
                  <span v-if="item.nodeAddr">{{ item.nodeAddr || "-" }}</span>
                  <ImageUpload
                    v-if="item.files"
                    :model-value.sync="item.files"
                    mode="preview"
                  />
                </div>
              </div>
            </div>
            <!-- <div v-if="!carDetail.carNodeVOList.length" class="no-data">暂无数据</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/utils';
// import Big from 'big.js';
export default {
  filters: {
    parseTime,
  },
  props: {
    carDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    dialogMarkerData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 车单履约状态
      carOrderPerformanceStateMap: {
        3: '执行中', // 待运输
        5: '执行中', // 已到装
        7: '执行中', // 已装货
        8: '执行中', // 已到卸
        9: '运输完成', // 运输完成
        '-1': '运输完成', // 已核定
        12: '运输完成', // 已结算
        13: '运输完成', // 已开票
      },
      processNodeData: [
        { nodeType: '1', nodeTime: '' },
        { nodeType: '5', nodeTime: '' },
        { nodeType: '7', nodeTime: '' },
        { nodeType: '8', nodeTime: '' },
        { nodeType: '9', nodeTime: '' },
      ],
      processNodeDataNew: [],
      typeMap: {
        1: '派单',
        5: '到装',
        7: '装货',
        8: '到卸',
        9: '卸货',
      },
    };
  },

  watch: {
    'carDetail.carNodeVOList'(val) {
      this.transformData(val);
    },
  },
  methods: {
    transformData(carNodeVOList) {
      const carNodeVOListNew = [...carNodeVOList];
      this.processNodeData.forEach(item => {
        const found = carNodeVOListNew.find(node => node.nodeType === item.nodeType);
        if (!found) {
          const newNode = { ...item };
          Object.keys(newNode).forEach(key => {
            if (newNode[key] === '') {
              newNode[key] = '';
            }
          });
          carNodeVOListNew.push(newNode);
        }
      });
      this.processNodeDataNew = carNodeVOListNew;
    },
    returnColor() {
      if ([9, '-1', 12, 13].includes(this.carDetail.state)) {
        return '#1A6AFF';
      } else {
        return '#10CE66';
      }
    },
    returnBackground() {
      if ([9, '-1', 12, 13].includes(this.carDetail.state)) {
        return 'rgba(26, 106, 255, 0.1)';
      } else {
        return 'rgba(16, 206, 102, 0.1)';
      }
    },
  },
};

</script>
<style lang='scss' scoped>
.right-panel-wrap{
  // background: #fff;
  // padding: 15px;
  // border-radius: 8px;
  // font-size: 14px;
  .car-order-detail-wrap {
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 999;
    .card-wrap {
      // min-width: 430px;
      width: 520px;
      min-height: 57px;
      background: #ffffff;
      border-radius: 4px;
      padding: 17px 20px 20px 20px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
      .address-wrap {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(99,102,108,0.12);
        .left-address{
          flex:1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .address {
            width: 180px;
            text-align: center;
            >div{
              &:first-child{
                font-size: 24px;
              }
              &:last-child{
                color: rgba(21,24,29,0.64);
                margin-top: 10px;
              }
            }
          }
          .arrow-wrap{
            text-align: center;
            .product,.weight{
              font-size: 14px;
              color: rgba(21,24,29,0.64);
            }
            .product{
              margin-bottom: -10px;
            }
            .weight{
              margin-top: -5px;
            }
            img{
              width: 60px;
            }
          }
        }
      }
      .detail-info-wrap{
        .box-title{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left{
            color: rgba(16,16,16,1);
            // font-size: 16px;
          }
          .right{
            display: flex;
            align-items: center;
            // font-size: 12px;
            color: $blue;
            img{
              margin-left: 5px;
              cursor: pointer;
            }
          }
        }
        .box-card-wrap {
          margin-top: 16px;
          >div{
            display: flex;
            align-items: center;
            >div{
              width: 33.3%;
              .value{
                color: #101010;
                font-weight: bold;
              }
            }
          }
          &>:last-child{
            margin-top: 10px;
            >div{
              width: 100%;
            }
          }
        }
      }
    }
    .car-info-detail-wrap {
      .car-info {
        display: flex;
        .top-car-che {
          width: 88px;
          line-height: 28px;
          text-align: center;
          border-radius: 4px;
          background: url("~@/assets/images/monitor/carBg2.png") no-repeat;
          background-size: 100% 100%;
          font-size: 14px;
          color: #04122b;
          margin-right: 10px;
        }
        .top-car-type {
          line-height: 28px;
          padding: 0 12px;
          background: rgba(16, 206, 102, 0.1);
          border-radius: 4px;
          color: #10ce66;
          font-size: 14px;
          font-weight: 400;
          position: relative;
          margin-right: 30px;
          .status-text {
            // width: 42px;
            text-align: center;
          }
        }
      }
      .people-company-info {
        margin-top: 10px;
        color: #58595c;
        .compamy-wrap {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
          span {
            font-weight: bold;
          }
        }
        .people-wrap {
          display: flex;
          > div {
            display: flex;
            align-items: center;
            width: 50%;
            i {
              font-size: 20px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .time-line-wrap {
      .km {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        i {
          font-size: 20px;
          margin-right: 8px;
        }
        > div {
          display: flex;
          flex: 1;
          width: 100%;
          > div {
            width: 40%;
            display: flex;
            flex-direction: column;
            > span {
              font-weight: bold;
            }
            .label {
              color: #58595c;
              font-weight: normal;
            }
          }
        }
      }
      .w-line-show {
        height: calc(100vh - 640px);
        max-height: 285px;
        overflow-y: scroll;
        .item {
          padding: 5px 0;
          display: flex;
          .icon-wrap{
            height: 20px;
            width: 20px;
            border-radius: 20px;
            border: 1px solid #c0c4cc;
            margin-right: 15px;
            text-align: center;
            color: #c0c4cc;
          }
          .right-wrap {
            flex: 1;
            position: relative;
            min-height: 50px;
            &::before {
              content: "";
              position: absolute;
              display: block;
              width: 1px;
              border-left: 1px solid #c0c4cc;
              top: 20px;
              margin-left: -25px;
              height: calc(100% - 10px);
              background: transparent;
            }
            .line-item-top{
              >div{
                .node-text {
                  color: #272727;
                  font-size: 16px;
                  font-weight: bold;
                }
                .dun-text{
                  color: rgba(16,16,16,0.6);
                  margin-left: 3px;
                }
              }
              .w-time {
                color: rgba(21,24,29,0.64);
              }
            }
            .line-item-bottom {
              display: flex;
              flex-direction: column;
              >span {
                color: rgba(21,24,29,0.64);
              }
            }
          }
          &.active-item {
            .icon-wrap{
              border: 1px solid #10C060;
              color: #10C060;
            }
            .right-wrap {
              flex: 1;
              position: relative;
              &::before {
                border-left: 1px solid #10C060;
              }
            }
          }
        }
        >:last-child{
          .right-wrap {
            position: relative;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>

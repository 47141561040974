<template>
  <div class="user-info-card-wrap">
    <div :class="['user-info-content', { 'show-bg-content': showBg },`${sizeType}-type-content`]" @click="toUserDetail">
      <div class="user-info-left">
        <img v-if="newUserInfo.userLogo||newUserInfo.logo" :src="`${tinyimg_url}browse?fileCode=${newUserInfo.userLogo||newUserInfo.logo}`">
        <img v-else src="@/assets/images/common/user_icon.svg">
      </div>
      <div class="user-info-right">
        <div class="top">
          <span class="name ellipsis">{{ newUserInfo.userName||newUserInfo.userNickName||'-' }}</span><span :class="`real-name ${userPassFlagColorTypeMap[newUserInfo.userPassFlag]}`">{{
            userPassFlagTypeMap[newUserInfo.userPassFlag]
          }}</span>
        </div>
        <div class="bottom">
          <!-- <el-tooltip
            effect="dark"
            content="业务资质出现此图标，这表示用户当前发单资质与名片上展示业务资质存在不一致的情况，请关注。"
            placement="top"
          >
            <i class="iconfont icon-question-line" /> </el-tooltip><span class="company ellipsis">{{ newUserInfo.titleName }}</span> -->
          <template v-if="needMatch">
            <i v-if="newUserInfo.userCompanyName!==newUserInfo.titleName" class="iconfont icon-question-line" @click.stop="dialogVisible=true" /> <span class="company ellipsis">{{ newUserInfo.userCompanyName||'-' }}</span>
          </template>
          <template v-else>
            <span class="company ellipsis">{{ newUserInfo.titleName||'-' }}</span>
          </template>
          <i
            :class="`iconfont icon-verified-badge-fill ${newUserInfo.titlePassFlag === 4 ? 'green-c' : 'grey-c'}`"
          />
        </div>
      </div>
    </div>
    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      width="300px"
      center
      :append-to-body="true"
      custom-class="tip-dialog"
    >
      <div class="content-wrap">
        <div class="bg-pic">
          <img src="@/assets/images/common/light-bg-que.png">
        </div>
        <div class="explain">
          <div class="explain-title"><i class="iconfont icon-question-line" />解释说明</div>
          <div class="explain-content flexc">
            <!-- <span>
              业务资质出现此图标，这表示用户当
            </span>
            <span>前发单资质与名片上展示业务资质存</span>
            <span>在不一致的情况，请关注。</span> -->
            业务资质出现此图标，这表示用户当前发单资质与名片上展示业务资质存在不一致的情况，请关注。
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const tinyimg_url = process.env.VUE_APP_MINIO_URL;
export default {
  props: {
    // 是否展示背景色
    showBg: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 尺寸 type normal正常 table特有样式
    sizeType: {
      type: String,
      default() {
        return 'normal';
      },
    },
    needMatch: {
      type: Boolean,
      default() {
        return true;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {
          userId: '',
          logo: '',
          userName: '',
          userPassFlag: 0, // 实名认证状态 1已实名 2未实名 3审核中 4认证失败
          userCompanyName: '',
          titleName: '',
          titlePassFlag: 0, // 资质认证标识 1: '未认证',2: '认证中', 3: '已驳回',4: '已认证',
        };
      },
    },
    // 是否需要转换数据
    needChangeUserInfo: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // 货主1/承运商2/
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    // 允许跳转详情
    allowToDetail: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      tinyimg_url,
      // userPassFlagTypeMap: {
      //   1: '已实名',
      //   2: '未实名',
      //   3: '审核中',
      //   4: '认证失败',
      // },
      // userPassFlagColorTypeMap: {
      //   1: 'real-green',
      //   2: 'real-grey',
      //   3: 'real-blue',
      //   4: 'real-red',
      // },
      userPassFlagTypeMap: {
        0: '未实名',
        1: '已实名',
      },
      userPassFlagColorTypeMap: {
        0: 'real-grey',
        1: 'real-green',
      },
      dialogVisible: false,
      newUserInfo: {},
    };
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val) {
          if (this.needChangeUserInfo) {
            this.newUserInfo = this.getDefaultUserInfo(val);
          } else {
            this.newUserInfo = JSON.parse(JSON.stringify(val));
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    getDefaultUserInfo(data) {
      const { customerUserVO = {}} = data;
      return {
        userId: customerUserVO.userId || data.customerUserId	|| '', // userId
        userLogo: customerUserVO.userLogo, // logo
        userName: data.customerUserName || data.userName, // 用户姓名/发单人
        userCompanyName: data.customerName || data.titleName, // 公司/发单人公司
        userPassFlag: customerUserVO.userPassFlag, // 用户实名状态
        titleName: customerUserVO.titleName, // 资质名称用作比对和发单人公司是否一致使用
        // titlePassFlag: data.customerUserVO.titlePassFlag, // 资质认证状态
        titlePassFlag: data.titlePassFlag, // 资质认证状态
      };
    },
    toUserDetail() {
      if (!this.allowToDetail) return;
      // 1 货主 3车队外协 4车队直发 source
      const sourceChangeUserTypeMap = {
        1: '1',
        2: '2',
        3: '2',
        4: '2',
      };
      this.$router.push(`/user/personal/detail?id=${this.newUserInfo.userId}&type=${sourceChangeUserTypeMap[this.userType] || 1}`);
    },
  },
};

</script>
<style lang="scss">
.tip-dialog{
  word-break: break-all;
  word-wrap: break-word;
  .content-wrap{
    margin: -72px -24px 0 -24px;
  }
  .dialog-footer{
    margin-top: 15px;
  }
  .bg-pic{
    height: 62px;
    background: url("~@/assets/images/common/light-bg.png") center center no-repeat;
    background-size: cover;
    text-align: right;
    img{
      margin-right: 11px;
    }
  }
  .explain{
    margin-top: -32px;
    .explain-title{
      text-align: center;
      color: #15181D;
      font-size: 17px;
      i{
        color: $red;
        font-size: 18px;
        margin-right: 2px;
      }
    }
    .explain-content{
      padding: 12px 20px;
      font-size: 15px;
      color: #15181D;
    }
  }
}
</style>
<style lang="scss" scoped>
.user-info-card-wrap {
  .show-bg-content {
    background: #dce0e7;
    padding: 6px 3px;
    border-radius: 5px;
  }
  .user-info-content {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    .user-info-left{
      width: 28px;
      height: 28px;
      margin-right: 5px;
      img {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        background-color: rgba(229, 229, 229, 1);
      }
    }
    .user-info-right {
      width: calc(100% - 33px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        display: flex;
        align-items: center;
        .name {
          color: #15181d;
          margin-right: 3px;
        }
        .real-name {
          padding: 1px 3px;
          border-radius: 2px;
          font-size: 10px;
        }
        .real-green {
          background-color: #e6f3ff;
          color: #94c4ff;
        }
        .real-grey {
          background-color: #e0e4e7;
          color: #b6b4b4;
        }
        .real-blue {
          background-color: #6796ed;
          color: #fff;
        }
        .real-red {
          background-color: #f17f7f;
          color: #fff;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        i {
          font-size: 13px;
        }
        .icon-question-line {
          color: #f17f7f;
          cursor: pointer;
        }
        .company {
          color: rgba(21, 24, 29, 0.64);
          font-size: 11px;
        }
      }
    }
  }
  .table-type-content{
    height: 30px;
    .user-info-right {
      .top {
        height: 15px;
        .real-name {
          height: 14px;
          line-height: 14px;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 8px;
        }
      }
      .bottom {
        height: 15px;
        .company {
          font-size: 10px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="card-board-wrap">
    <template v-if="$store.state.common.AUV_ROLE_CODE!=='PT005'">
      <div class="board-1">
        <div class="title">用户数据（本月新增）</div>
        <div class="nav-wrap">
          <div class="nav-item item1">
            <div>货主人员</div>
            <div>
              <span>156</span>
              <span>+10</span>
            </div>
          </div>
          <div class="nav-item item2">
            <div>货主资质</div>
            <div>
              <span>156</span>
              <span>+10</span>
            </div>
          </div>
          <div class="nav-item item3">
            <div>承运商人员</div>
            <div>
              <span>156</span>
              <span>+10</span>
            </div>
          </div>
          <div class="nav-item item4">
            <div>承运商资质</div>
            <div>
              <span>156</span>
              <span>+10</span>
            </div>
          </div>
          <div class="nav-item item5">
            <div>实名认证</div>
            <div>
              <span>156</span>
              <span class="percent">80%</span>
            </div>
          </div>
          <div class="nav-item item6">
            <div>业务资质认证</div>
            <div>
              <span>156</span>
              <!-- <span>10</span> -->
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="board-wrap flex">
        <div class="board-2">
          <div class="title">用户数据（本月新增）</div>
          <div class="nav-wrap">
            <div class="nav-item item1">
              <div>承运商人员</div>
              <div>
                <span>156</span>
                <span>+10</span>
              </div>
            </div>
            <div class="nav-item item4">
              <div>承运商资质</div>
              <div>
                <span>156</span>
                <span>+10</span>
              </div>
            </div>
            <div class="nav-item item5">
              <div>车辆</div>
              <div>
                <span>156</span>
                <span>+10</span>
              </div>
            </div>
            <div class="nav-item item6">
              <div>驾押人员</div>
              <div>
                <span>156</span>
                <span>+10</span>
              </div>
            </div>
          </div>
        </div>
        <div class="board-3">
          <div class="title">本月找车（今日新增）</div>
          <div class="nav-wrap item1">
            <div class="nav-item">
              <div>货单</div>
              <div>
                <span class="fz_20">156</span>
                <span>+17</span>
              </div>
            </div>
            <div class="nav-item">
              <div>上报车数</div>
              <div>
                <span class="fz_20">1000</span>
                <span>+17</span>
              </div>
            </div>
            <div class="nav-item">
              <div>确认车数</div>
              <div>
                <span class="fz_20">800</span>
                <span>+17</span>
                <span class="percent-2">80%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {

  components: {},

  data() {
    return {
    };
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {},
};

</script>
<style lang='scss' scoped>
.card-board-wrap{
  .title{
    font-weight: bold;
    margin-bottom: 10px;
  }

  .nav-wrap{
    display: flex;
    justify-content: space-between;
    .nav-item{
      // width: calc(16.6% - 16px);
      // margin-right: 16px;
      height: 100px;
      border-radius: 7px;
      color: #101010;
      font-size: 14px;
      text-align: center;
      // padding-left: 16px;
      padding-top: 17px;
      >div{
        // &:first-child{
        //   font-size: 12px;
        // }
        &:last-child{
          margin-top: 20px;
          position: relative;
          >span{
            &:nth-child(2){
              position: absolute;
              top: -10px;
              font-size: 12px;
              color: red;
            }
          }
          .percent{
            top: 0;
            // font-size: 14px!important;
            margin-left: 5px;
            color: rgba(16,16,16,0.58);
          }
          .percent-2{
            position: absolute;
            top: 5px;
            // font-size: 14px!important;
            right: 0px;
            color: rgba(16,16,16,0.58);
          }
        }
      }
    }
  }
  .board-1{
    .nav-item{
      width: calc(16.6% - 16px);
    }
  }
  .board-2{
    width: 60%;
    margin-right: 15px;
    .nav-item{
      width: calc(26.6% - 16px);
    }
  }
  .board-3{
    width: 40%;
    .nav-wrap{
      border-radius: 7px;
      .nav-item{
        width:100%;
      }
    }
  }
  .item1{
    background: linear-gradient(91.59deg, rgba(26,106,255,0.15) 48.65%,rgba(94,143,230,0) 144.11%);
  }
  .item2{
    background:  linear-gradient(89.74deg, rgba(242,91,77,0.15) 50.23%,rgba(201,215,215,0) 160.03%);
  }
  .item3{
    background: linear-gradient(89.74deg, rgba(242,91,77,0.15) 50.23%,rgba(201,215,215,0) 160.03%);;
  }
  .item4{
    background:  linear-gradient(91.59deg, rgba(255,174,26,0.15) 48.65%,rgba(201,215,215,0) 150.2%);
  }
  .item5{
    background: linear-gradient(91.59deg, rgba(129,179,55,0.15) 48.65%,rgba(201,215,215,0) 148.17%);
  }
  .item6{
    background: linear-gradient(91.59deg, rgba(164,164,164,0.15) 48.65%,rgba(201,215,215,0) 150.2%);
  }
}
</style>

<template>
  <div class="new-add-wrap">
    <CardBoard />
    <ChartList class="chart-wrap" />
  </div>
</template>

<script>
import CardBoard from './newAddAndChart/cardBoard';
import ChartList from './newAddAndChart/chartList';
export default {
  components: {
    CardBoard,
    ChartList,
  },
  props: {
  },
  data() {
    return {

    };
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {},
};

</script>
<style lang='scss' scoped>
.chart-wrap{
  margin-top: 10px;
  // height: 200px;
}
</style>

<template>
  <div class="carrier-report-wrap">
    <div class="g-search-form-table">
      <Table
        v-loading="loading"
        :table-cols="tableCols"
        :table-data="tableData"
        :show-pagination="true"
        :pagination="pagination"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <template v-slot="{ col, row }">
          <!-- 类型 enable2取消 3改派 4已操作过的取消 -->
          <div v-if="col.prop === 'enable'" class="ellipsis">
            <span>车辆{{ enableMap[row.goodsCapacity] }}</span>
          </div>
          <div v-if="col.prop === 'truckNo'" class="ellipsis">
            <span class="mr5">{{ row.truckNo||'-' }}</span>
            <span :class="$CONSTANT.carTypeColorMap[row.isWx]">{{ $CONSTANT.carTypeMap[row.isWx]||'-' }}</span>
          </div>
          <!--处理状态： state=3(待处理)(红色) 4已确认 5待处理 6已确认（展示图标跳转车单详情 ） -->
          <div v-if="col.prop === 'state'">
            <!-- <span :class="[3].includes(row.state)?'red-c':''">{{ col.rulesObj[row.state]||'-' }}</span>
            <span v-if="[6].includes(row.state)"><i class="iconfont icon-list-eye-line main-c-cursor" @click="$router.push(`/transport/vehicleBill/detail?id=${row.relId}`)" /></span> -->
            <span :class="[2].includes(row.enable)?'red-c':''">{{ enableTransStateMap[row.enable]||'-' }}</span>
            <span v-if="[4,6].includes(row.enable)&&row.carOrderSimpleListVO"><i class="iconfont icon-list-eye-line main-c-cursor" @click="$router.push(`/transport/vehicleBill/detail?id=${row.carOrderSimpleListVO.id}`)" /></span>
          </div>

          <div v-if="col.prop === 'driverName'" class="ellipsis">
            <span class="mr5">{{ row.driverName||'-' }}</span>
            <span>{{ row.driverPhone||'-' }}</span>
          </div>
          <div v-if="col.prop === 'escortName'" class="ellipsis">
            <span class="mr5">{{ row.escortName||'-' }}</span>
            <span>{{ row.escortPhone||'-' }}</span>
          </div>
          <!-- 4已上报给货主 -->
          <!-- 车辆取消(enable 2)+ext2为空的时候 此时需要显示操作 （暂时不需要判断 待处理（state3）&&[3].includes(row.state)）-->
          <div v-if="col.prop === 'action'&&([2].includes(row.enable))&&!row.ext2">
            <el-dropdown
              trigger="click"
              @command="(command)=>{
                handleCommand(command, row)}"
            >
              <span class="el-dropdown-link main-c-cursor">
                去处理<i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu v-if="type!=='CYHD'" slot="dropdown">
                <el-dropdown-item command="change">改派其他待提交车辆</el-dropdown-item>
                <el-dropdown-item command="reassign">推送小五，补报改派</el-dropdown-item>
                <el-dropdown-item command="cancel">同意取消并上报给货主</el-dropdown-item>
              </el-dropdown-menu>
              <el-dropdown-menu v-else slot="dropdown">
                <el-dropdown-item command="change">改派其他待提交车辆</el-dropdown-item>
                <el-dropdown-item v-if="showReassignAction" command="reassign">推送小五，补报改派</el-dropdown-item>
                <el-dropdown-item command="cancel">同意取消并上报给货主</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </Table>
    </div>
    <DialogTable :dialog-visible.sync="showDialog" dialog-title="推送小五，补报改派" :disable-i-d-list="disableIDList" @dialogSubmit="dialogSubmit" />
    <ChangeCarDia v-if="showChangeDialog" :dialog-visible.sync="showChangeDialog" :order-id="orderId" :car-id="changeCarId" :order-information="orderInformation" @reassignOtherCarSubmit="reassignOtherCarSubmit" />
  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
import supplyLookCar from '@/api/goodsOrder/supplyLookCar';
import DialogTable from '@/views/goodsOrder/components/detail/dialogTable';
import ChangeCarDia from '@/views/goodsOrder/components/detail/changeCarDia';
export default {
  components: { DialogTable, ChangeCarDia },
  props: {
    orderInformation: {
      type: Object,
      default() {
        return {};
      },
    },
    orderId: {
      type: [Number, String],
      default() {
        return 1;
      },
    },
    // QX货源承接详情 XW货源找车详情 CYHD承运货单详情
    type: {
      type: String,
      default() {
        return 'QX';
      },
    },
    // 是否展示操作列
    showAction: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      loading: false,
      showChangeDialog: false,
      changeCarId: '',
      enableMap: {
        // enable 1已处理改派 2取消 3改派 4已操作过的取消
        1: '改派',
        2: '取消',
        3: '改派',
        4: '取消',
      },
      enableTransStateMap: {
        // enable 2待处理 4已处理（待确认） 4已处理（待确认）relld存在 可跳转 6已处理（已取消）
        2: '待处理',
        4: '已处理（待确认）',
        6: '已处理（已取消）',
      },
      tableCols: [
        { label: '车单编号', prop: 'carSerial', width: 180 },
        { label: '车牌号', prop: 'truckNo', type: 'customize' },
        { label: '类型', prop: 'enable', type: 'customize' },
        // rulesObj: { 1: '正常', 2: '待处理', 3: '待处理', 4: '待确认', 5: '待确认', 6: '已确认', 7: '已取消' },
        { label: '处理状态', prop: 'state', type: 'customize',
          rulesObj: { 3: '待处理', 4: '已确认', 5: '待处理', 6: '已确认' }, width: '145' },
        { label: '承运人', type: 'userInfoCard', width: 165, userType: 2 },
        { label: '申请时间', prop: 'updateTime', type: 'filterTime', width: '165' },
        { label: '挂车号', prop: 'guaNo' },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 160 },
        { label: '押运员', prop: 'escortName', type: 'customize', width: 160 },
        { label: '货主确认时间', prop: 'confirmTime', type: 'filterTime', width: '165' },
        { label: '小五姓名', type: 'objShow', objShow: { keyPar: 'xwUserVO', keyChild: 'name' }},
      ],
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      currentData: {},
      addselectUser: [],
      showDialog: false,
      showReassignAction: false, // 是否展示改派操作
      disableIDList: [], // 已推送小五userId
    };
  },
  watch: {
    orderInformation: {
      handler: function(val) {
        if (val && this.type === 'CYHD') {
          // 承运货单详情-取消改派操作需要状态state是否报停8来判断展示 未报停!8取消该派都展示 已报停8展示同意取消操作 隐藏改派操作
          this.showReassignAction = +val.state !== 8;
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.showAction) {
      this.tableCols.push({ label: '操作', fixed: 'right', prop: 'action', type: 'customize' });
    }
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      const params = {
        gId: this.orderId,
        current: this.pagination.page,
        size: this.pagination.size,
        goodsCapacity: '2,3',
      };
      const apiMap = {
        'QX': supplyTask.orderCarQxChangeList,
        'XW': supplyLookCar.carXwChangeList,
        'CYHD': supplyTask.orderCarQxChangeList,
      };
      apiMap[this.type](params).then(res => {
        this.tableData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    handleCommand(command, row) {
      // 推送小五，补报改派
      if (command === 'reassign') {
        this.pushXW(row);
      }
      // 同意取消并上报给货主
      if (command === 'cancel') {
        this.pushOwner(row);
      }
      // 改派其他待提交车辆
      if (command === 'change') {
        this.changeCarId = row.id;
        this.showChangeDialog = true;
      }
    },
    getHasPushXwList() {
      supplyTask.orderGoodsRecordXwUserList({ id: this.orderId }).then(res => {
        this.disableIDList = res || [];
      }).finally(() => {
        this.showDialog = true;
      });
    },
    // 推送小五，补报改派
    pushXW(data) {
      this.currentData = data;
      this.getHasPushXwList(data);
    },
    dialogSubmit(data) {
      this.addselectUser = data;
      this.pushXWSubmit();
    },
    pushXWSubmit() {
      const params = {
        gId: this.orderId, // 货单Id
        type: 2, // 2推送小五 3推送车队
        ext3: this.currentData.id, // 车单Id
        truckNum: 1, // 车队取消车辆 前线推送小五，补报改派操作时 此处车数写死1
        // priority: this.pushSetBaseInfo.priority, // 优先级
        // price: this.pushSetBaseInfo.price, // 费用
        // isPushPlatForm: this.pushSetBaseInfo.isPushPlatForm, // 是否推送大厅
        // priceType: this.pushSetBaseInfo.priceType, // 费用类型 1运价  2信息费
        // needTax: this.pushSetBaseInfo.needTax, // 是否含税 1含税 0不含税
        // enable: 1, // 0货单未承接 1货单已承接
        userIdList: this.addselectUser.map(d => d.id) || [], // 小五UserId列表
      };
      supplyTask.orderGoodsPushXw(params).then(res => {
        if (res) {
          this.$baseMessage('提交成功!', 'success');
          this.getList();
        }
      });
    },
    // 同意取消并上报给货主
    pushOwner(data) {
      const params = {
        id: 6, // 1 取消车辆  6确认申请  7拒绝申请
        // ids:  key车单ID  vulue补偿金额(车队申请没有，默认为空)
        ids: {
          [data.id]: '',
        },
      };
      supplyTask.orderCarCancel(params).then(res => {
        if (res) {
          this.$baseMessage('操作成功!', 'success');
          this.getList();
          this.$emit('actionSuccess');
        }
      });
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 改派其他待提交车辆操作成功
    reassignOtherCarSubmit() {
      this.getList();
      this.$emit('actionSuccess');
    },
  },
};

</script>
<style lang='scss' scoped>
.carrier-report-wrap{
  .g-table-c{
    padding: 0;
  }
}
</style>

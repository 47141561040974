import Vue from 'vue';

// import Element from 'element-ui';
// import '@/assets/scss/element-variables.scss';
// import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue';
import router from './router';
import store from './store';
import directive from './directive'; // directive

import 'whatwg-fetch';
import 'custom-event-polyfill';
import 'core-js/stable/promise';
import 'core-js/stable/symbol';
import 'core-js/stable/string/starts-with';
import 'core-js/web/url';

import './assets/iconfont/iconfont.css';

// 全局插件
import './plugins';
import './permission';
/* 全局事件总线 */
Vue.prototype.$eventBus = new Vue();
/* 全局常量挂载 */
import globalConstant from '@/config/globalConstant';
Vue.prototype.$CONSTANT = globalConstant;

// 全局数字放大缩小方法挂载
import { parseNumber } from './utils';
Vue.prototype.$parseNumber = parseNumber;

// Vue.use(Element, {
//   size: 'medium', // set element-ui default size
// });

Vue.config.productionTip = false;
window._AMapSecurityConfig = {
  securityJsCode: '1382381a6bd39bf592fb655339bd9d39',
};
Vue.use(directive);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');


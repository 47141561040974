/*
  重要参数
  noCrumbPath: 面包屑导航无法通过点击跳转，例：没有实际页面，只是为了展示层级
  hideInMenu: 是否在菜单显示
  hideInCrumb：是否在面包屑显示，用于隐藏菜单带参数路径
*/
const menuList = [
  {
    code: '10',
    title: '首页',
    path: '/',
    icon: 'index',
    children: [
      {
        code: '10',
        title: '消息中心',
        path: '/msgCenter',
        hideInMenu: true,
      },
    ],
  },
  {
    code: '200',
    title: '企业管理',
    path: '/business',
    icon: 'business',
    noCrumbPath: true,
    children: [
      {
        code: '220',
        title: '个人用户管理',
        path: '/user/personal',
        children: [
          {
            code: '220',
            title: '个人用户详情',
            path: '/user/personal/detail',
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    code: '300',
    title: '基础数据',
    path: '/basicData',
    icon: 'basicData',
    noCrumbPath: true,
    children: [
      {
        code: '310',
        title: '产品管理',
        path: '/basicData/product',
      },
      {
        code: '320',
        title: '常用地址',
        path: '/basicData/usualAddress',
      },
      {
        code: '330',
        title: '业务线路',
        path: '/basicData/businessLine',
      },
      {
        code: '340',
        title: '驾驶员',
        path: '/basicData/driver',
      },
      {
        code: '350',
        title: '押运员',
        path: '/basicData/escort',
      },
      {
        code: '360',
        title: '车辆',
        path: '/basicData/truck',
      },
      {
        code: '370',
        title: '挂车',
        path: '/basicData/trailer',
      },
      {
        code: '380',
        title: '运力组合',
        path: '/basicData/capacity',
      },
      // {
      //   code: '39',
      //   title: '客户管理',
      //   path: '/basicData/customer',
      // },
    ],
  },
  {
    code: '400',
    title: '货单管理',
    path: '/goodsOrder',
    icon: 'huodan',
    noCrumbPath: true,
    children: [
      // {
      //   code: '42',
      //   title: '货单列表',
      //   path: '/goodsOrder/orderList',
      //   children: [
      //     {
      //       code: '42',
      //       title: '货源详情',
      //       path: '/goodsOrder/orderList/detail',
      //       hideInMenu: true,
      //     },
      //   ],
      // },
      {
        code: '430',
        title: '货源承接',
        path: '/goodsOrder/supplyTask',
        children: [
          {
            code: '430',
            title: '货源详情',
            path: '/goodsOrder/supplyTask/detail',
            hideInMenu: true,
          },
        ],
      },
      {
        code: '440',
        title: '货源找车',
        path: '/goodsOrder/supplyLookCar',
        children: [
          {
            code: '440',
            title: '货源详情',
            path: '/goodsOrder/supplyLookCar/detail',
            hideInMenu: true,
          },
        ],
      },
      // {
      //   code: '41',
      //   title: '货源大厅',
      //   path: '/goodsOrder/supplyHall',
      // },
    ],
  },
  {
    code: '500',
    title: '运输管理',
    path: '/transport',
    icon: 'transport',
    noCrumbPath: true,
    children: [
      {
        code: '530',
        title: '承运货单',
        path: '/transport/goods',
        children: [
          {
            code: '530',
            title: '承运货单详情',
            path: '/transport/goods/detail',
            hideInMenu: true,
          },
        ],
      },
      {
        code: '510',
        title: '运单列表',
        path: '/transport/wayBill',
        children: [
          {
            code: '510',
            title: '运单详情',
            path: '/transport/wayBill/detail',
            hideInMenu: true,
          },
        ],
      },
      {
        code: '520',
        title: '车单列表',
        path: '/transport/vehicleBill',
        children: [
          {
            code: '520',
            title: '车单详情',
            path: '/transport/vehicleBill/detail',
            hideInMenu: true,
          },
        ],
      },
      {
        code: '540',
        title: '履约动态',
        path: '/transport/monitor',
      },
      {
        code: '540',
        title: '车辆查询',
        path: '/transport/map',
      },
    ],
  },
  // {
  //   code: '60',
  //   title: '财务风控',
  //   path: '/financial',
  //   icon: 'report',
  //   noCrumbPath: true,
  //   children: [
  //     {
  //       code: '61',
  //       title: '卸结模板',
  //       path: '/financial/PriceModuleList',
  //     },
  //     {
  //       code: '62',
  //       title: '阳光运企业',
  //       path: '/financial/PriceModuleWhiteList',
  //       children: [
  //         {
  //           code: '62',
  //           title: '历史记录',
  //           path: '/financial/PriceModuleWhiteList/history',
  //           hideInMenu: true,
  //         },
  //       ],
  //     },
  //     {
  //       code: '63',
  //       title: '阳光种子企业',
  //       path: '/financial/sunseed',
  //       children: [
  //         {
  //           code: '63',
  //           title: '历史记录',
  //           path: '/financial/sunseed/history',
  //           hideInMenu: true,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    code: '70',
    title: '系统管理',
    path: '/system',
    noCrumbPath: true,
    icon: 'system',
    children: [
      {
        code: '71',
        title: '用户管理',
        path: '/system/user',
      },
      {
        code: '72',
        title: '角色管理',
        path: '/system/role',
      },
      {
        code: '73',
        title: '权限管理',
        path: '/system/permission',
      },
      // {
      //   code: '74',
      //   title: '用户操作日志',
      //   path: '/system/logs',
      // },
    ],
  },
];

export default menuList;

<template>
  <div class="left-carrier-wrap">
    <el-select v-model="companyValue" class="company-select" placeholder="全部承运商" clearable @change="changeCompany">
      <el-option
        v-for="item in companyList"
        :key="item.id"
        :label="item.userName"
        :value="item.userId"
        class="company-list-options"
      >
        <div class="list-wrap flex-b-c">
          <UserInfoCard style="max-width: 230px;" :allow-to-detail="false" :user-info="item" />
          <div class="ml5">{{ item.carTotal }} 车</div>
        </div>
      </el-option>
    </el-select>
    <div class="input-car">
      <el-button class="car-button" icon="el-icon-search" type="primary" @click="searchMark" />
      <el-select v-model="carValue" filterable clearable placeholder="请输入车牌号" :popper-append-to-body="false" @focus="resultList">
        <el-option v-for="item in carList" :key="item.id" :label="item.truckNo" :value="item.truckNo" />
      </el-select>
    </div>
  </div>
</template>

<script>
import { shareGoodsOrderDetail } from '@/api/transport/monitor/transportMap';
export default {
  props: {
    currentGoodsOrder: {
      type: Object,
      default() {
        return {};
      },
    },
    allCarData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      companyList: [],
      companyValue: '',
      carList: [],
      carValue: '',
    };
  },
  watch: {
    currentGoodsOrder(val) {
      if (val) {
        this.companyList = [];
        this.companyValue = '';
        this.carList = [];
        this.carValue = '';
        this.getWayBillUserList(val);
      }
    },
  },
  methods: {
    // 获取承运商列表
    getWayBillUserList(data) {
      const { extTypeMap = {}} = data;
      const { custList = [] } = extTypeMap;
      const companyList = custList.map(d => {
        const { goodsReportVO = {}, userVO = {}} = d;
        return {
          ...d,
          customerUserVO: userVO,
          customerName: goodsReportVO.titleName || '-',
          customerUserName: userVO.userName || '-',
          userCompanyName: goodsReportVO.titleName || '-',
          titlePassFlag: goodsReportVO.titleState || '-',
          id: goodsReportVO.id,
          userName: userVO.userName,
          userId: userVO.userId,
        };
      });
      this.companyList = companyList;
    },
    // 选择承运商
    changeCompany(e) {
      const params = {
        userId: +this.$store.state.common.AUV_USER_ID,
        custId: e, // 承运商id
        gId: this.currentGoodsOrder.id, // 货单id 不传默认查货单列表第一条得详情
      };
      shareGoodsOrderDetail(params).then(res => {
        const { extTypeMap } = res;
        if (extTypeMap.carList) {
          this.carList = Object.values(extTypeMap.carList).reduce((acc, val) => acc.concat(val), []) || [];
        } else {
          this.carList = [];
        }
      });
    },
    resultList() {
      if (!this.companyValue) {
        this.carList = Object.values(this.allCarData).reduce((acc, val) => acc.concat(val), []) || [];
      }
      // this.carList = Object.freeze([...this.allCarData.values()]);
    },
    searchMark() {
      if (this.carValue) {
        this.$emit('leftSearchMark', this.carValue);
      }
    },
  },
};

</script>
<style lang='scss' scoped>
.left-carrier-wrap{
  display: flex;
}
.company-select{
  width: 280px;
  margin-right: 10px;
}
.input-car {
  display: flex;
  width: 280px;
  ::v-deep.el-input-group__append,
  .el-input-group__prepend {
    background-color: #409eff;
    color: #fff;
  }
}
.company-list-options{
  height: 40px;
  .list-wrap{
    line-height: 16px;
    padding: 5px 0;
  }
}
</style>

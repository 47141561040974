import { toView, parseBetyData } from './libs';

class Socket {
  constructor() {
    this.socket = null;
    this.onMessageHandle = null;
    this.onCloseHandle = null;
    this.onErrorHandle = null;
  }

  // 初始化
  init(url) {
    if ('WebSocket' in window) {
      this.socket = new window.WebSocket(url);
      this.socket.binaryType = 'arraybuffer';
      this.socket.onmessage = (e) => {
        if (this.onMessageHandle) {
          const data = parseBetyData(e.data);
          this.onMessageHandle(this.dataFormat(data));
        }
      };
      this.socket.onclose = (e) => {
        if (this.onCloseHandle) {
          this.onCloseHandle(e);
        }
      };
      this.socket.onerror = (e) => {
        if (this.onErrorHandle) {
          this.onErrorHandle(e);
        }
      };
    }
  }
  // 连接
  open(data, cb) {
    console.warn('socket连接中...');
    this.socket.onopen = () => {
      console.warn('socket连接成功');
      this.send(data);
      if (cb) {
        cb();
      }
    };
  }
  // 发送
  send(data) {
    const dataView = toView(data.msg, ...data.type);
    this.socket.send(dataView);
  }
  // 关闭
  close() {
    if (this.socket) {
      console.warn('socket已断开');
      this.socket.close();
      this.socket = null;
    }
  }
  // 收到消息时
  onMessage(cb) {
    this.onMessageHandle = cb;
  }
  // 关闭时
  onClose(cb) {
    this.onCloseHandle = cb;
  }
  // 异常时
  onError(cb) {
    this.onErrorHandle = cb;
  }
  // 格式化接受数据
  dataFormat(wsData) {
    const data = wsData[0];
    const type = wsData[1];
    let res = {};
    switch (type) {
      case 0x0a:
        if (data.d && data.d.length) {
          const list = data.d.map((val) => {
            return {
              lnglat: `${val.b / 1000000},${val.c / 1000000}` // 经纬度
            };
          });
          res = {
            type: 'LINE_DATA',
            data: list
          };
        }
        break;
      case 0x20:
        res = {
          type: 'CURTENT_CAR_DATA',
          data
        };
        break;
      case 0x22:
        res = {
          type: 'CAR_EVENT_DATA',
          data
        };
        break;
      case 0x24:
        res = {
          type: 'CAR_HISTORY_DATA',
          data
        };
        break;
      case 0x25:
        res = {
          type: 'CAR_HISTORY_NEW_DATA',
          data
        };
        break;
    }
    return res;
  }
}

export default Socket;

<template>
  <div v-if="showFloat">
    <transition name="slide-fade">
      <el-button
        class="floating-button"
        type="primary"
        circle
        :style="{ top: `${buttonPosition.y}px`, right: `15px`, transition: isDragging ? 'none' : 'top 0.3s ease, right 0.3s ease' }"
        @mousedown.native="startDrag"
        @click="toggleDialog"
      >
        <i class="el-icon-download fz_20" />
        <span v-if="$store.state.common.bandExportingLength" class="circle-loading" />
      </el-button>
    </transition>
    <el-dialog
      custom-class="export-band-dialog-wrap"
      title="磅单导出"
      :visible="dialogVisible"
      width="400px"
      top="20vh"
      :show-close="false"
      :destroy-on-close="true"
      @open="open"
      @close="close"
    >
      <div class="minimize-wrap">
        <i class="el-icon-minus cursor hover-opacity" @click="setBandExportDialog(false)" />
      </div>
      <div
        v-loading="loading"
        element-loading-text="下载中，请稍等"
        element-loading-spinner="el-icon-loading"
        class="export-list-wrap"
      >
        <div v-if="exportData.length" class="list-wrap">
          <div v-for="(item, index) in exportData" :key="index" class="export-item">
            <!-- 0删除 1 生成中 2已完成 3失败 4无磅单数据-->
            <div v-if="item.state===1">
              <span class="main-c hover-opacity">{{ item.fileName }} 生成中...</span>
              <el-tooltip effect="dark" content="删除" placement="top">
                <i class="el-icon-close cursor red-c ml10 hover-opacity" @click="delData(item)" />
              </el-tooltip>
            </div>
            <div v-else-if="item.state===2">
              <span class="main-c-cursor hover-opacity" @click="download(item)">{{ item.fileName }} 生成成功，点击下载</span>
              <el-tooltip effect="dark" content="删除" placement="top">
                <i class="el-icon-close cursor red-c ml10 hover-opacity" @click="delData(item)" />
              </el-tooltip>
            </div>
            <div v-else-if="item.state===3">
              <span class="red-c hover-opacity">{{ item.fileName }} 生成失败</span>
              <el-tooltip effect="dark" content="删除" placement="top">
                <i class="el-icon-close cursor red-c ml10 hover-opacity" @click="delData(item)" />
              </el-tooltip>
            </div>
            <div v-else-if="item.state===4">
              <span class="red-c hover-opacity">{{ item.fileName }} 无磅单数据</span>
              <el-tooltip effect="dark" content="删除" placement="top">
                <i class="el-icon-close cursor red-c ml10 hover-opacity" @click="delData(item)" />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div v-else class="no-data">暂无数据</div>
        <el-pagination
          v-if="pagination.total>10"
          class="text-align-c mt10"
          :current-page.sync="pagination.page"
          :page-size.sync="pagination.size"
          :total="pagination.total"
          layout="prev, pager, next"
          background
          small
          @current-change="currentChange"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import poundList from '@/api/pound';
const tinyimg_url = process.env.VUE_APP_MINIO_URL;
import axios from 'axios';
export default {
  data() {
    return {
      tinyimg_url,
      // showFloatBall: false,
      isDragging: false,
      buttonPosition: { y: window.innerHeight - 70 }, // 初始位置：右下角
      startY: 0,
      exportData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: false,
    };
  },
  computed: {
    showFloat() {
      return !['/404'].includes(this.$route.path);
    },
    dialogVisible() { return this.$store.state.common.bandExportDialog; },
  },
  mounted() {
    this.$store.dispatch('common/getBandExportIngNum', { var1: 1 });
    // 监听窗口大小变化
    window.addEventListener('resize', this.updateButtonPosition);
  },
  beforeDestroy() {
    // 在组件销毁时移除监听器
    window.removeEventListener('resize', this.updateButtonPosition);
  },
  methods: {
    ...mapMutations({
      setBandExportDialog: 'common/setBandExportDialog',
    }),
    getList() {
      const params = {
        size: this.pagination.size,
        current: this.pagination.page,
      };
      poundList.downloadPoundBillList(params).then(res => {
        this.exportData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).catch((error) => {
        console.log(error, 'error');
      });
    },
    open() {
      this.pagination = {
        page: 1,
        size: 10,
        total: 0,
      };
      this.getList();
      this.$store.dispatch('common/getBandExportIngNum', { var1: 1 });
    },
    close() {
      this.setBandExportDialog(false);
    },
    toggleDialog() {
      this.setBandExportDialog(!this.dialogVisible);
    },
    // toggleFloatingButton() {
    //   this.showFloatBall = !this.showFloatBall;
    // },
    startDrag(event) {
      this.isDragging = true;
      this.startY = event.clientY - this.buttonPosition.y;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        // 只允许垂直移动，限制范围在屏幕内
        let newY = event.clientY - this.startY;
        newY = Math.max(0, Math.min(newY, window.innerHeight - 50));
        this.buttonPosition.y = newY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    updateButtonPosition() {
      // 当窗口调整大小时更新按钮位置，确保按钮不超出屏幕
      this.buttonPosition.y = Math.min(this.buttonPosition.y, window.innerHeight - 50);
    },
    // 下载磅单文件
    download(data) {
      this.loading = true;
      // 创建FormData对象，并向其中添加文件和其他表单数据
      const formData = new FormData();
      formData.append('fileCode', data.fileCode);
      axios
        .post('/configure/file/download', formData, { responseType: 'blob' })
        .then((e) => {
          // 得到请求到的数据后，对数据进行处理
          const blob = new Blob([e.data], { type: 'application/zip' }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          const link = document.createElement('a'); // 创建一个a标签
          link.download = data.fileName; // 设置a标签的下载属性
          link.style.display = 'none'; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          setTimeout(() => {
            URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
            document.body.removeChild(link); // 移除a标签
          }, 3000);
          this.$baseMessage(`${data.fileName}下载完成！`, 'success');
        })
        .catch((error) => {
          console.log(error, 'error');
          this.$baseMessage(`${data.fileName}下载失败！`, 'error');
        }).finally(() => {
          this.loading = false;
        });
    },
    delData(data) {
      poundList.deldownloadPoundBill({ id: data.id }).then(res => {
        if (res) {
          this.$baseMessage(`${data.fileName}删除成功！`, 'success');
          if ((this.pagination.total - 1 === (this.pagination.page - 1) * this.pagination.size) && this.pagination.page > 1) {
            this.pagination.page = this.pagination.page - 1;
          }
          this.getList();
        } else {
          this.$baseMessage(`${data.fileName}删除失败！`, 'success');
        }
      });
    },
    currentChange(page) {
      this.pagination.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-button {
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: top 0.3s ease, right 0.3s ease;
  cursor: pointer;
  right: 20px; /* 固定在右侧 */
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.circle-loading{
  position: absolute;
  top:0;
  right: 2px;
  // color:red;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: red;
}
::v-deep{
  .export-band-dialog-wrap{
    .el-dialog__header{
      padding: 16px 20px;
    }
  }
}
.minimize-wrap{
  position: absolute;
  right: 20px;
  top: 10px;
}
.export-list-wrap{
  min-height: 290px;
  // padding-bottom: 20px;
  .list-wrap{
    height: 240px;
    padding-left: 25px;
    .export-item{
      padding-bottom: 5px;
      font-size: 15px;
    }
  }
  .no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
}
</style>

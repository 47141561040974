<template>
  <div :id="mapId" class="map-container" />
</template>

<script>
import axios from 'axios';
import xml2js from 'xml2js';
import Socket from './socket';
import LOAD_ICON from '@/assets/images/map/car_load.png';
import UNLOAD_ICON from '@/assets/images/map/car_unload.png';
const WS_URL = 'ws://49.4.68.207:9400/ws';
const TIAN_MAP_KEY = 'eeac90adaa0a87378d2473bbdb1ac428';
const T = window.T;

const centerLng = 118.625730;
const centerLat = 32.081320;

export default {
  props: {
    mapId: {
      type: String,
      default: 'map',
    },
    // 是否自动移动点位效果
    isAutoMove: {
      type: Boolean,
      default: true,
    },
    // 推送得车辆数据转换映射关系
    transformDataMappingRules: {
      type: Object,
      default() {
        return {
          carNo: 'a',
          lgt: 'b',
          lat: 'c',
          speed: 'd',
          direction: 'f',
          // state: 'g'
        };
      },
    },
  },
  data() {
    return {
      map: null, // 存储地图实例
      vehicleManager: {}, // 车辆管理器 (车辆点位 车辆移动轨迹)
      markerMap: {}, // 打普通点的集合
      polylineList: {}, // 线路规划得集合（起始点得之间规划线路）
      polyline: null, // 点集合绘制出来的线路轨迹
      totalCarList: [], //
    };
  },
  mounted() {
    this.initMapHandle();
  },
  methods: {
    // 订阅车辆
    subscribeCars(data) {
      const build = [];
      data.forEach((item) => {
        build.push({
          carNo: item,
          state: 3,
        });
      });
      this.totalCarList = build;
      this.initSocketHandle(data);
    },
    // 取消订阅车辆
    unsubscribeCars() {
      if (this.socket) {
        this.socket.close();
      }
      this.removeMarkAndPolyLine();
    },
    // 转换映射
    transformData(data, mapping) {
    // 创建一个新的对象用于保存转换后的结果
      const transformed = {};
      // 遍历映射规则对象
      for (const key in mapping) {
        // eslint-disable-next-line no-prototype-builtins
        if (mapping.hasOwnProperty(key)) {
          const rule = mapping[key];
          // 如果规则是一个字符串，则直接进行键名的映射
          if (typeof rule === 'string') {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty(rule)) {
              transformed[key] = data[rule];
            }
          }
        }
      }
      return transformed;
    },
    // 初始化socket
    initSocketHandle(cars) {
      this.socket = new Socket();
      this.socket.init(WS_URL);
      this.socket.open({ msg: '15139fef1515149921bc8c4803f2', type: [0, 1, 0] }, () => {
        this.socket.send({ msg: { a: cars }, type: [0, 32, 0] });
      });
      this.socket.onMessage((cbData) => {
        const { type, data } = cbData;
        if (type === 'CURTENT_CAR_DATA') {
          const newData = this.transformData(data, this.transformDataMappingRules);
          this.drawVehicleHandle(newData, { lon: newData.lgt / 1000000, lat: newData.lat / 1000000 });
        }
      });
    },

    // 初始化地图
    initMapHandle() {
      // // 底图图层
      // const baseImgUrl = `http://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${TIAN_MAP_KEY}`;
      // const baseLayer = new T.TileLayer(baseImgUrl, { minZoom: 1, maxZoom: 18 });
      // // 路网图层
      // const roadImgUrl = `http://t1.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${TIAN_MAP_KEY}`;
      // const roadLayer = new T.TileLayer(roadImgUrl, { minZoom: 1, maxZoom: 18 });
      this.map = new T.Map(this.mapId, {
        // layers: [baseLayer, roadLayer], // 不加载，使用默认图层
      });
      this.map.centerAndZoom(new T.LngLat(centerLng, centerLat), 6);
      this.$emit('load', {
        map: this.map,
        T,
      });
    },
    // 起始点画线
    drawStartAndEndHandle(lnglatList) {
      if (lnglatList.length === 2) {
        this.planLine(lnglatList);
        this.drawMark(lnglatList[0], LOAD_ICON);
        this.drawMark(lnglatList[1], UNLOAD_ICON);
        this.map.setViewport([new T.LngLat(lnglatList[0].longitude, lnglatList[0].latitude), new T.LngLat(lnglatList[1].longitude, lnglatList[1].latitude)]);
      } else {
        console.error('起始点数据缺失');
      }
    },
    // 规划线路
    planLine(points, color) {
      if (points.length < 2) {
        return Promise.reject('位置点少于两个');
      }
      const proList = [];
      points.forEach((item, index) => {
        const nextItem = points[index + 1];
        if (nextItem) {
          proList.push(this.drive([`${item.longitude},${item.latitude}`, `${nextItem.longitude},${nextItem.latitude}`]));
        }
      });
      return Promise.all(proList).then((resAll) => {
        let points = [];
        let distance = 0; // 总距离(km)
        let duration = 0; // 总时间(s)
        resAll.forEach((item) => {
          points = [...points, item.lngLat];
          distance += Number(item.distance);
          duration += Number(item.duration);
        });
        if (points) {
          const drivePlan = new T.Polyline(points, {
            color: color || '#0091ff',
            opacity: 1,
            weight: 6,
          });
          const markerId = this.getMIdHandle();
          this.polylineList[markerId] = {
            polyline: drivePlan,
          };
          this.map.addOverLay(drivePlan);
          return {
            line: drivePlan,
            distance,
            duration,
          };
        }
      });
    },
    // 打普通点 自定义icon
    drawMark(point, icon, iconSize, iconAnchor) {
      const markerIcon = new T.Icon({
        iconUrl: icon,
        iconSize: new T.Point(iconSize || 40, 40),
        iconAnchor: new T.Point(iconAnchor || 20, 40),
      });
      const marker = new T.Marker(new T.LngLat(point.longitude, point.latitude), { icon: markerIcon });
      const markerId = this.getMIdHandle();

      // this.carDataMap[markerId] = {
      //   carMarker: marker,
      // };
      this.markerMap[markerId] = {
        carMarker: marker,
      };
      this.map.addOverLay(marker);
    },
    // 处理车辆 初次打点 后者移动位置
    drawVehicleHandle(data, newPosition) {
      const { carNo } = data;
      if (!this.vehicleManager[carNo]) {
        // 动态初始化车辆（如果尚未初始化）
        this.addVehicleMarker(data, newPosition);
      } else {
        if (this.isAutoMove) {
        // 动态更新车辆位置
          this.updateVehiclePosition(data, newPosition);
        } else {
          // 移除上一次得打点标记
          this.map.removeOverLay(this.vehicleManager[carNo].marker);
          // 静态更新车辆位置
          this.addVehicleMarker(data, newPosition);
        }
      }
    },
    // 动态初始化车辆
    addVehicleMarker(data, initialPosition) {
      if (this.map) {
        const { carNo, lgt, lat, speed, direction, state = 3 } = data;
        const carMarker = new T.Label({
          text: `
          <div class="tdt-car-marker">
            <div class="tdt-car-icon tdt-state-${state}" style="transform: rotate(${direction - 90}deg)"></div>
            <div class="tdt-car-info ${speed > 0 ? '' : 'tdt-car-stop'}">
              <div class="tdt-car-speed">
                <div class="tdt-speed-num">${speed}</div>
                <div class="tdt-speed-unit">km/h</div>
              </div>
              <div class="tdt-car-num">${carNo}</div>
            </div>
          </div>
        `,
          position: new T.LngLat(lgt / 1000000, lat / 1000000),
          offset: new T.Point(-32, -6),
        });
        carMarker.on('click', () => {
          this.$emit('markerClick', data);
        });
        this.vehicleManager[carNo] = {
          marker: carMarker,
          lastPos: null, // 上一次的位置
          currentPos: initialPosition, // 当前的位置
          isFirstUpdate: true, // 标记是否是第一次更新
        };
        this.map.addOverLay(this.vehicleManager[carNo].marker);
      }
    },
    // 更新车辆位置
    updateVehiclePosition(data, newPosition) {
      const { carNo } = data;
      const vehicle = this.vehicleManager[carNo];
      if (vehicle) {
        // 检查新位置是否与上次位置一致
        if (vehicle.currentPos.lat !== newPosition.lat && vehicle.currentPos.lon !== newPosition.lon) {
          // 如果新位置不同，启动移动逻辑
          vehicle.lastPos = vehicle.currentPos; // 记录上一次的位置
          vehicle.currentPos = newPosition; // 更新当前位置
          // 开始移动到新位置
          this.moveVehicleToNextPosition(data, vehicle);
        }
      }
    },
    async moveVehicleToNextPosition(data, vehicle) {
      const { carNo, speed } = data;
      if (vehicle.lastPos && vehicle.currentPos) {
        const imgMap = {
          3: require('@/assets/images/map/car_arr_blue.svg'),
          5: require('@/assets/images/map/car_arr_green.svg'),
          9: require('@/assets/images/map/car_arr_grey.svg'),
        };
        if (vehicle.isFirstUpdate === true) {
          // this.map.panTo(new T.LngLat(118.913544, 32.167918), 15);
          const pointsFirst = await this.getRoutePlan(new T.LngLat(vehicle.lastPos.lon, vehicle.lastPos.lat), new T.LngLat(vehicle.currentPos.lon, vehicle.currentPos.lat));
          vehicle.isFirstUpdate = false;
          this.vehicleManager[carNo] = {
            carTrack: new T.CarTrack(this.map, {
              interval: 210, // 200从当前节点到下一节点的时间间隔单位是毫秒。
              speed: 2, // 3一个时间间隔移动的距离，单位是米。 注：speed为0时，按照Datas数组中每个元素的坐标移动。
              dynamicLine: false,
              Datas: pointsFirst,
              carstyle: {
                iconUrl: imgMap[data.state || 3],
                width: 26,
                height: 26,
              },
              polylinestyle: { color: 'transport', width: 5, opacity: 0.1 },
              onClick: (event, carTrackInstance) => {
                this.$emit('markerClick', data, carTrackInstance);
              },
            }),
            ...vehicle,
          };
          this.vehicleManager[carNo].carTrack.start();
        } else {
          vehicle.isFirstUpdate = false;
          const currentPosition = this.vehicleManager[carNo].carTrack.carMarker.lnglat; // 此方法返回当前经纬度
          const targetPosition = new T.LngLat(vehicle.currentPos.lon, vehicle.currentPos.lat);
          const points = await this.getRoutePlan(currentPosition, targetPosition);
          if (points.length && this.vehicleManager[carNo].carTrack) {
            this.vehicleManager[carNo].carTrack.clear();
            this.vehicleManager[carNo].carTrack = null;
            this.vehicleManager[carNo].carTrack = new T.CarTrack(this.map, {
              interval: 210, // 从当前节点到下一节点的时间间隔。
              speed: 2, // 一个时间间隔移动的距离，单位是米。 注：speed为0时，按照Datas数组中每个元素的坐标移动。
              dynamicLine: false,
              Datas: points,
              carstyle: {
                iconUrl: imgMap[data.state || 3],
                width: 26,
                height: 26,
              },
              polylinestyle: { color: 'transport', width: 5, opacity: 0.1 },
              onClick: (event, carTrackInstance) => {
                this.$emit('markerClick', data, carTrackInstance);
              },
            });
            this.vehicleManager[carNo].carTrack.start();
          }
        }
        // 移除初次得打点标记
        this.map.removeOverLay(this.vehicleManager[carNo].marker);
        const img = this.vehicleManager[carNo].carTrack.carMarker.img;
        if (img) {
          const markerInfoWindows = `<div class="tdt-car-marker">
              <div class="tdt-car-info tdt-car-info-move ${speed > 0 ? '' : 'tdt-car-stop'}">
                <div class="tdt-car-speed">
                  <div class="tdt-speed-num">${speed}</div>
                  <div class="tdt-speed-unit">km/h</div>
                </div>
                <div class="tdt-car-num">${carNo}</div>
              </div>
            </div>`;
          this.vehicleManager[carNo].carTrack.carMarker.div.style.width = '150px';
          this.vehicleManager[carNo].carTrack.carMarker.img.style.cursor = 'pointer';
          // this.vehicleManager[carNo].carTrack.carMarker.div.innerHTML = img.outerHTML + markerInfoWindows;
          img.insertAdjacentHTML('afterend', markerInfoWindows);
        }
      }
    },
    async getRoutePlan(start, end) {
      if (start.lng === end.lng && start.lat === end.lat) {
        return [];
      } else {
        const driveRes = await this.drive([`${start.lng},${start.lat}`, `${end.lng},${end.lat}`]);
        if (driveRes?.lngLat) {
          return driveRes.lngLat;
        } else {
          return [];
        }
      }
    },
    // 查看单点聚焦
    setSinglePointViewPort(lnglat) {
      if (lnglat.longitude && lnglat.latitude) {
        this.map.centerAndZoom(new T.LngLat(lnglat.longitude, lnglat.latitude), 18); // 你可以根据需要调整缩放级别
      } else {
        console.error('经纬度数据缺失');
      }
    },
    // 利用点集合绘制轨迹
    drawPolyLine(lnglats, color) {
      // 清除之前的轨迹
      if (this.polyline) {
        this.map.removeOverlay(this.polyline);
      }
      const points = [];
      for (let i = 0; i < lnglats.length; i = i + 1) {
        points.push(new T.LngLat(lnglats[i].longitude, lnglats[i].latitude));
      }
      // 定义行驶轨迹的样式
      const style = {
        color: color || '#07C160', // 线条颜色
        opacity: 0.8, // 线条透明度
        weight: 5, // 线条宽度
      };
      this.line = new T.Polyline(points, style);
      this.map.addOverLay(this.line);
    },
    // 清除marker line
    removeMarkAndPolyLine() {
      // 清除车辆相关信息
      if (this.vehicleManager) {
        for (const key in this.vehicleManager) {
          if (this.vehicleManager[key].carTrack) {
            this.vehicleManager[key].carTrack.clear();
            this.vehicleManager[key].carTrack = null;
          }
          this.map.removeOverLay(this.vehicleManager[key].marker);
        }
        this.vehicleManager = {};
      }
      // 清除普通点
      if (this.markerMap) {
        for (const key in this.markerMap) {
          this.map.removeOverLay(this.markerMap[key].carMarker);
        }
        this.markerMap = {};
      }
      // 清除点集合绘制出来的轨迹线
      if (this.polyline) {
        this.map.removeOverlay(this.polyline);
        this.polyline = null;
      }
      // 清除规划线路
      if (this.polylineList) {
        for (const key in this.polylineList) {
          this.map.removeOverLay(this.polylineList[key].polyline);
        }
        this.polylineList = {};
      }
    },
    // 生成标记id
    getMIdHandle() {
      return `${new Date().getTime()}_${Math.floor(100000 + Math.random() * 900000)}`;
    },
    // 驾驶线路推荐
    drive(data) {
      return new Promise((resolve, reject) => {
        if (!data || data.length < 2) {
          reject();
        }
        const startPoint = data[0];
        const endPoint = data[1];
        const build = [];
        axios.get(`http://api.tianditu.gov.cn/drive?postStr={"orig":"${startPoint}","dest":"${endPoint}","style":"0"}&type=search&tk=${TIAN_MAP_KEY}`).then((r) => {
          this.xmlFormatHandle(r.data).then((res) => {
            const { result = {}} = res;
            const { routelatlon = [], distance = [], duration = [] } = result;
            const list = routelatlon[0] ? routelatlon[0].split(';') : [];
            const points = list.map(function(t) {
              return JSON.parse('['.concat(t, ']'));
            });
            points.forEach((val) => {
              if (val[0] && val[1]) {
                build.push(new T.LngLat(val[0], val[1]));
              }
            });
            resolve({
              distance: distance[0],
              duration: duration[0],
              lngLat: build,
            });
          });
        });
      });
    },
    // 根据关键字搜索地址
    searchByKeyWord(data) {
      const { keyWord, count = 10 } = data;
      return new Promise((resolve) => {
        if (!keyWord) {
          resolve([]);
        }
        axios.get(`http://api.tianditu.gov.cn/geocoder?ds={"keyWord":"${keyWord}"}&tk=${TIAN_MAP_KEY}`).then((r) => {
          if (r.status === 200) {
            const { data } = r;
            const { location = {}} = data;
            const { lat, lon } = location;
            if (!lat || !lon) {
              resolve([]);
            }
            axios.get(`http://api.tianditu.gov.cn/v2/search?postStr={"keyWord":"${keyWord}","level":18,"queryRadius":10000,"pointLonlat":"${lon},${lat}","queryType":3,"start":0,"count":${count}}&type=query&tk=${TIAN_MAP_KEY}`).then((res) => {
              if (res.status === 200) {
                const { data } = res;
                const { pois } = data;
                resolve(pois);
              }
            });
          }
        });
      });
    },
    // 根据经纬度获取地址信息
    getAddress(data) {
      return new Promise((resolve, reject) => {
        axios.get(`http://api.tianditu.gov.cn/geocoder?postStr={'lon':${data[0]},'lat':${data[1]},'ver':1}&type=geocode&tk=${TIAN_MAP_KEY}`).then((res) => {
          if (res) {
            resolve(res.data);
          } else {
            reject();
          }
        });
      });
    },
    // 格式化xml数据
    xmlFormatHandle(xml) {
      return new Promise((resolve, reject) => {
        xml2js.parseString(xml, (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
.tdt-control-copyright {
  display: none;
}
.tdt-label {
  padding: 0;
  border: 0;
  box-shadow: none;
  background: none;
}
.tdt-car-marker {
  position: relative;
  .tdt-car-icon {
    width: 26px;
    height: 26px;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100%;
    &.tdt-state-3 {
      background-image: url(../../assets/images/map/car_arr_blue.svg);
    }
    &.tdt-state-5, &.tdt-state-7, &.tdt-state-8 {
      background-image: url(../../assets/images/map/car_arr_green.svg);
    }
    &.tdt-state-9 {
      background-image: url(../../assets/images/map/car_arr_grey.svg);
    }
    &.tdt-state-fixicon {
      background-image: url(../../assets/images/map/car.png);
    }
  }
  .tdt-car-info {
    position: absolute;
    top: -45px;
    left: -45px;
    display: flex;
    align-items: center;
    &.tdt-car-stop {
      .tdt-car-speed {
        border-color: #58595c;
        .tdt-speed-num {
          color: #58595c;
        }
        .tdt-speed-unit {
          color: #58595c;
        }
      }
    }
    &.tdt-car-error {
      .tdt-car-speed {
        border-color: #EF5063;
        .tdt-speed-num {
          color: #EF5063;
        }
        .tdt-speed-unit {
          color: #EF5063;
        }
      }
    }
    .tdt-car-speed {
      width: 48px;
      height: 48px;
      background-color: #fff;
      border-radius: 50%;
      border: solid 2px rgba(0,170,91,1);
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      z-index: 1;
      line-height: 14px;
      padding: 10px 0;
      .tdt-speed-num {
        text-align: center;
        width: 100%;
        color: rgba(0,170,91,1);
        font-size: 16px;
      }
      .tdt-speed-unit {
        text-align: center;
        width: 100%;
        color: rgba(0,170,91,1);
        font-size: 14px;
      }
    }
    .tdt-car-num {
      background-color: rgba(247,199,88,1);
      font-size: 14px;
      color: #000;
      border: solid 1px rgba(16,16,16,1);
      border-radius: 4px;
      margin-left: -20px;
      padding: 0 10px 0 20px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .tdt-car-info-move{
    top:-75px;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}
</style>

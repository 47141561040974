<template>
  <div class="index-wrap">
    <div class="left-wrap">
      <NewAddAndChart v-if="isfold" class="top" />
      <div class="arrow-wrap">
        <div :class="`arrow ${isfold ? 'arrow-up' : 'arrow-down'}`" @click="fold">
          <i :class="isfold ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
          <span>{{ isfold?'':'用户数据' }}</span>
        </div>
      </div>
      <Commission :isfold="isfold" :class="['bottom',isfold ? 'bottom-up' : 'bottom-down']" />
    </div>
    <div class="right-wrap">
      <Notice />
      <Remind />
    </div>
  </div>
</template>

<script>
import NewAddAndChart from './components/newAddAndChart';
import Commission from './components/commission';
import Notice from './components/notice';
import Remind from './components/remind';
export default {
  components: { NewAddAndChart, Commission, Notice, Remind },
  data() {
    return {
      isfold: false,
    };
  },
  created() {
    // this.getMessage();
    // this.$eventBus.$on('getMsg', this.getMsg);
    // setInterval(() => {
    // this.getMessage();
    // }, 6000);
  },
  destroyed() {
    // this.$eventBus.$off('getMsg', this.getMsg);
  },
  methods: {
    fold() {
      this.isfold = !this.isfold;
    },
    // getMsg(data) {
    // this.noticeList = data.filter(d => d.messageType === 0);// 通知
    // this.statisticsList = data.filter(d => d.messageType === 1);// 待办
    // },
  },
};

</script>
<style lang='scss' scoped>
.index-wrap{
  display: flex;
  height: 100%;
  .left-wrap{
    width: 70%;
    height: calc(100vh - 120px);
    position: relative;
    .top,.bottom{
      padding: 15px 15px 5px 15px;
      border-radius: 4px;
      background: #fff;
    }
    .top{
      height: 360px;
      margin-bottom: 12px;
    }
    .bottom{
      height: 100%;
    }
    .bottom-up{
      height: calc(100% - 370px);
    }
    .bottom-down{
      height: 100%;
    }
    .arrow-wrap {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: -12px;
      display: flex;
      justify-content: center;
      .arrow {
        padding:2px 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 0 0 4px;
        background: #fff;
        color: $blue;
        i {
          color: $blue;
        }
        span{
          display: inline-block;
          font-size: 12px;
          margin-top: 4px;
          line-height: 17px;
        }
      }
      .arrow-up{
        box-shadow: -1px -5px 4px 0 rgba(0, 0, 0, 0.1);
      }
      .arrow-down{
        box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
      }
    }
  }
  .right-wrap{
    flex: 1;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    margin-left: 15px;
  }
}
</style>

<template>
  <div class="bar-chart-wrap">
    <div id="main3" ref="chart" style="height: 180px;" />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { parseTime } from '@/utils';
// import Big from 'big.js';
export default {
  filters: {
    parseTime,
  },
  props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      chart: null,
      isFirst: true,
    };
  },
  watch: {
    chartData: {
      handler() {
        // console.log(123);
        // this.drawChart();
      },
      deep: true,
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.chart.resize());
  },
  created() {
    // console.log(456);
    // this.drawChart();
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(789);
      this.drawChart();
    });
  },
  methods: {
    drawChart() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '15%',
          containLabel: true,
        },
        legend: {
          data: ['总承运吨数', '总单数'],
          show: true,
          type: 'plain',
          right: 0,
          top: '-2%',
          // bottom: '-2%',
          // icon: 'rect', // 设置图例标记为矩形
          // itemWidth: 25, // 设置小横线的宽度
          // textStyle: {
          //   color: '#333', // 文字颜色设置为图表的颜色
          // },
          // itemWidth: 25, // 矩形宽度
          // itemHeight: 1, // 矩形高度
        },

        xAxis: [
          {
            type: 'category',
            data: ['货主1', '货主2', '货主3', '货主4', '货主5', '货主6', '货主7', '货主8', '货主9', '货主10', '货主11', '货主12'],
            axisPointer: {
              type: 'shadow',
            },
            // axisLine: { show: true, lineStyle: { color: '#D9D9D9' }},
          },
        ],
        yAxis: [
          {
            type: 'value',
            // name: '总承运吨数',
            // min: 0,
            // max: 400000,
            // interval: 50000,
            splitNumber: 5,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed', // 设置为虚线
              },
            },
          },
          {
            type: 'value',
            // name: '总单数',
            // min: 0,
            // max: 12000,
            // interval: 2000,
            splitNumber: 5,
            splitLine: {
              // 网格线
              show: false,
            },
          },
        ],
        series: [
          {
            name: '总承运吨数',
            type: 'bar',
            barWidth: 36,
            tooltip: {
              valueFormatter: function(value) {
                return value + '吨';
              },
            },
            // label: {
            //   show: true,
            //   position: 'top',
            //   color: '#5087EC',
            // },
            itemStyle: {
            // 设置柱状图的颜色
              color: '#4874CB',
            },
            data: [
              20000, 49000, 70000, 232000, 256000, 76000, 135600, 164522, 321446, 204510, 624004, 303000,
            ],
          },
          {
            name: '总单数',
            type: 'line',
            // stack: 'Total',

            symbol: 'none', // 触摸的时候没有圆圈出现
            showSymbol: false, // 默认展示的时候没有圆圈出现
            // label: {
            //   show: true,
            //   position: 'top',
            //   color: '#68BBC4',
            // },
            // 设置折线颜色
            lineStyle: {
              color: '#EC8B41', // 这里设置为红色
              // width: 2, // 设置线宽
            },
            itemStyle: {
              color: '#68BBC4',
            },
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function(value) {
                return value + '元';
              },
            },
            data: [2000, 2002, 3300, 4500, 6003, 10200, 2030, 21034, 2300, 1065, 12000, 6002],
          },
        ],
      };
      this.$nextTick(() => {
        if (this.isFirst) {
          // this.chart = echarts.init(this.$refs.chart);
          this.chart = echarts.init(document.getElementById('main3'));
          this.chart.setOption(option, true);
          this.isFirst = false;
        } else {
          this.chart.setOption(option, true);
        }
        // this.chart.setOption(option, true);
        window.addEventListener('resize', () => {
          if (this.chart) {
            this.chart.resize();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>

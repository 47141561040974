<template>
  <div class="timeline">
    <div v-for="(n) in processNodesData" :key="n.nodeType" :class="['timeline-item',+n.num>0?'active':'']">
      <div class="timeline-item_wrap">
        <div class="title">
          {{ n.label }}
          <span v-if="+n.num>0" class="num">{{ n.num }}</span>
        </div>
      </div>
      <div class="timeline-item_icon">
        <span class="quan" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nodeData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      stateMap: {
        1: '车数',
        3: '车号',
        5: '到装',
        7: '装货',
        8: '到卸',
        9: '卸货',
      },
      processNodesData: [],
    };
  },
  watch: {
    nodeData(val) {
      this.getData(val);
    },
  },
  methods: {
    getData(nodeData) {
      const transNodeData = [];
      for (const key in this.stateMap) {
        if (nodeData[key] >= 0) {
          transNodeData.push({
            nodeType: Number(key),
            label: this.stateMap[key],
            num: nodeData[key],
          });
        }
      }
      this.processNodesData = transNodeData;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  display: flex;
  padding-left: 25px;
  .timeline-item {
    flex: 1;
    .timeline-item_wrap {
      .title {
        font-size: 14px;
        color: #c0c4cc;
        margin-left: -8px;
        .num {
          font-size: 12px;
          color: #5a6073;
        }
      }
    }
    .timeline-item_icon {
      width: 12px;
      height: 12px;
      background: #c0c4cc;
      border: 1px solid #c0c4cc;
      color: #c0c4cc;
      border-radius: 50%;
      line-height: 12px;
      text-align: center;
      i {
        font-size: 16px;
      }
    }
    &::after{
      margin-top: -7px;
      display: block;
      content: '';
      height: 2px;
      width: 100%;
      background: #c0c4cc;
    }
    &:last-child{
      flex: 0.6;
      &::after{
        display: none;
      }
    }
  }
  .active {
    .timeline-item_icon {
      color: #10ce66;
      border-color: #10ce66;
      background: #10ce66;
    }
    .timeline-item_wrap {
      .title {
        color: #04122b;
      }
    }
    &::after{
      background: #10ce66;
    }
  }
}
</style>

<template>
  <div class="notice-wrap">
    <div class="title-wrap">
      <div class="title">通知</div>
      <div v-if="pagination.total" class="action">
        <span class="all-read" @click="readAll">全部已读</span>
        <el-tooltip
          content="清除"
          placement="top"
        >
          <img class="main-c-cursor" :src="require('@/assets/images/common/iconPark-clear.svg')" @click="remove">
        </el-tooltip>
      </div>
    </div>
    <div class="list-wrap">
      <div v-for="(item,index) in list" :key="index" class="list-item cursor" @click="toPage(item)">
        <div v-if="!item.msgState" class="circle" />
        <div class="nav-type">
          <div class="type">
            <img class="icon" src="@/assets/images/common/antFill-notification.svg">
            <span>{{ item.templateName }}</span>
          </div>
          <div class="time">{{ item.msgTime|parseTime }}</div>
        </div>
        <div class="nav-title">
          {{ item.msgContext }}
        </div>
      </div>
      <div v-if="!list.length" class="no-data">暂无数据</div>
    </div>
    <el-pagination
      class="msg-pagination-wrap"
      small
      layout="prev, pager, next"
      :pager-count="5"
      :total="pagination.total"
      :page-size="pagination.size"
      :current-page="pagination.page"
      @current-change="pageChange"
    />
  </div>
</template>

<script>
import { transportOrderMsgList, postMessageRead, removeMsg } from '@/api/message';
import { parseTime } from '@/utils';
export default {
  filters: { parseTime },
  data() {
    return {
      timer: null, // 定时器状态
      msgType: 2, // 1代办  2通知 3提醒
      list: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
    this.startTimer();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    startTimer() {
      if (this.timer) clearInterval(this.timer); // 清空上一个定时器
      this.timer = setInterval(() => {
        this.getList();
      }, 6000); // 每6秒发送一次请求
    },
    getList() {
      /**
      * state 状态 1待处理 2已过期  3接收 4拒绝 5忽略 -1查询已处理列表
      * type 代办类型 1关注 2同事 3资质
      *
      **/
      const params = {
        current: this.pagination.page,
        size: this.pagination.size,
        templateType: this.msgType, // 1代办  2通知 3提醒
        // msgState: 0, // 1已读  0未读
        msgFlag: 1, // 工作台显示
      };
      transportOrderMsgList(params).then(res => {
        this.list = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      });
    },
    // 全部已读
    async readAll() {
      const params = {
        id: this.msgType, // id: 消息类型 // 1代办  2通知 3提醒
      };
      await postMessageRead(params);
      this.getList();
    },
    // 消息清除
    async remove() {
      const params = {
        id: this.msgType, // id: 消息类型 // 1代办  2通知 3提醒
      };
      await removeMsg(params);
      this.getList();
    },
    // 通知类不跳转 只已读
    async toPage(data) {
      const params = {
        id: this.msgType, // id: 消息类型 // 1代办  2通知 3提醒
        ids: [data.id],
      };
      // 如果未读状态则先进行已读操作
      if (!data.msgState) {
        await postMessageRead(params);
        this.getList();
      }
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
  },
};

</script>
<style lang='scss' scoped>
.notice-wrap{
  padding: 15px 15px 20px 15px;
  background: #fff;
  height: calc(50% - 6px);
  .title-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-weight: bold;
    }
    .action{
      display: flex;
      align-items: center;
      font-size: 14px;
      .all-read{
        margin-right: 5px;
        cursor: pointer;
        &:hover{
          color: $blue;
        }
      }
    }
  }
  .list-wrap{
    padding-top: 10px;
    // height: calc(100% - 16px);
    height: calc(100% - 20px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: -15px;
    padding-right: 5px;
    font-size: 14px;
    .list-item{
      // min-height: 80px;
      margin-bottom: 6px;
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ccc;
      position: relative;
      .circle{
        height: 6px;
        width: 6px;
        border-radius: 6px;
        background: red;
        position: absolute;
        right: 8px;
        top: 5px;
      }
      .nav-type{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .type{
          display: flex;
          align-items: center;
          .icon{
            margin-right: 6px;
          }
          span{
            font-weight: bold;
          }
        }
        .time{
          color: #666666;
        }
      }
      .nav-title{
        padding: 10px 0;
      }
    }
  }
  .no-data{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666666;
  }
  .msg-pagination-wrap{
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: -2px;
    ::v-deep{
      button{
        padding: 0;
        min-width: 12px;
      }
      .number{
        margin: 0;
        padding: 0;
        min-width: 12px;
        line-height: 22px
      }
      .active {
        background-color: transparent !important;
        color: #4385FF;
      }
    }
  }
}
</style>

<template>
  <div class="car-log-wrap">
    <fullscreen :fullscreen.sync="fullscreen" class="fullMaps">
      <div v-loading="loading" class="fullMap">
        <!-- @load="mapLoadHandle"  -->
        <TMap ref="TianMap2" map-id="map2" :style="{ height: !fullscreen ? 'calc(100vh - 176px)' : '100vh' }" class="map" />
        <img :class="['full-img', fullscreen ? 'all-unfull' : 'all-full']" :src="require(`@/assets/images/monitor/${fullscreen ? 'allunfull' : 'allfull'}.svg`)" @click="screen">
        <RightPanel :car-detail="carDetail" :dialog-marker-data="dialogMarkerData" />
      </div>
    </fullscreen>
  </div>
</template>

<script>
import TMap from '@/components/TMap';
import RightPanel from './rightPanel';
import { shareCarOrderDetail } from '@/api/transport/monitor/transportMap';
import { getCarTrackByIdNew, getCarTrackNew } from '@/api/transport/monitor/transportMap';
import { parseTime } from '@/utils';
import pako from 'pako';
export default {
  components: { TMap, RightPanel },
  filters: {
    parseTime,
  },
  props: {
    carInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      carDetail: {}, // 车单详情信息
      // lnglatList: [], // 轨迹不加装卸货点经纬度集合
      loading: false,
      loadTableData: null, // 装货起点信息
      unloadTableData: null, // 卸货终点信息
      allCarLocationList: [], // 所有车辆的车牌和经纬度信息 便于地图打点显示车辆
      fullscreen: false,
      dialogMarkerData: {
        speed: 0, // 当前车速
        residueDistance: 0, // 剩余里程
        residueDriverTime: 0, // 预计剩余时长
        address: '', // 当前所在地
        driverDistance: 0, // 实际行驶里程
        driverTime: 0, // 实际行驶时间
      },
      // distanceCar: 0, // 今日里程
      // clickCarNo: '',
      isXie: false,
    };
  },
  watch: {
    'carInfo.id': {
      deep: true,
      immediate: true,
      handler: function(val) {
        this.getCarData(val);
      },
    },
  },
  methods: {
    screen() {
      this.fullscreen = !this.fullscreen;
    },
    // 获取车单详情数据
    getCarData(id) {
      if (this.$refs.TianMap2) {
        this.$refs.TianMap2.removeMarkAndPolyLine();
      }
      this.loading = true;
      const params = {
        userId: +this.$store.state.common.AUV_USER_ID,
        cId: id, // 车单id
      };
      shareCarOrderDetail(params)
        .then(async(res) => {
          this.carDetail = res || {};
          this.loadedAddress(res);
        })
        .finally(() => {
          // 有了车辆信息后 根据车辆信息加载地图信息
          this.loading = false;
          this.drawInitCar();
        });
    },
    // 装卸点信息
    loadedAddress(data) {
      const { carAddressVOList = [] } = data;
      if (carAddressVOList.length) {
        this.loadTableData = carAddressVOList.filter((val) => +val.type === 1);
        this.unloadTableData = carAddressVOList.filter((val) => +val.type === 2);
      }
    },
    // 车辆此时位置打点
    async drawMarkerCar() {
      const { lat, lgt, speed } = this.carInfo;
      if (lgt && lat) {
        // 根据车辆数据加载地图车实时位置
        this.$refs.TianMap2.addVehicleMarker(this.carInfo, { lon: lgt / 1000000, lat: lat / 1000000 });
        // 获取当前车速
        this.dialogMarkerData.speed = speed || 0;
        // 获取当前地址
        const addressRes = await this.$refs.TianMap2.getAddress([lgt / 1000000, lat / 1000000]);
        if (+addressRes.status === 0 && addressRes.result) {
          this.dialogMarkerData.address = addressRes.result.formatted_address || '';
        }
        // 获取剩余公里数 运输完成状态 不需要计算剩余距离和预计时长
        if (this.unloadTableData[0] && this.unloadTableData[0].addrPoint && ![9, '-1', 12, 13].includes(this.carDetail.state)) {
          const nowAddrPoint = `${lgt / 1000000},${lat / 1000000}`;
          // 根据当前点和终点 拿到距离和时间
          const residueInfo = await this.$refs.TianMap2.drive([nowAddrPoint, this.unloadTableData[0].addrPoint]);
          // 预计剩余距离
          this.dialogMarkerData.residueDistance = residueInfo?.distance || 0;
          // 预计剩余时长 路线预计
          // this.dialogMarkerData.residueDriverTime = this.timeComputed(residueInfo?.duration * 1000);
          // 预计剩余时长 路程/速度
          const H = this.getTimeH(this.dialogMarkerData.residueDistance, this.dialogMarkerData.speed);
          const M = this.getTimeM(this.dialogMarkerData.residueDistance, this.dialogMarkerData.speed);
          this.dialogMarkerData.residueDriverTime = `${H}h ${M}m`;
        }
        // const startLngLat = {
        //   lng: lgt / 1000000,
        //   lat: lat / 1000000,
        // };
        // const endLngLat = {
        //   lng: +this.unloadTableData[0].addrPoint.split(',')[0],
        //   lat: +this.unloadTableData[0].addrPoint.split(',')[1],
        // };
        // 116.478935, 39.997761
        // this.$refs.TianMap2.drawStartAndEndHandle2(startLngLat, endLngLat);
      }
    },
    // 绘制起点终点规划路线
    drawStartAndEndLine() {
      if (this.loadTableData && this.loadTableData[0].addrPoint && this.unloadTableData && this.unloadTableData[0].addrPoint) {
        const startLnglat = this.loadTableData[0].addrPoint;
        const endLnglat = this.unloadTableData[0].addrPoint;
        const lnglatList = [
          { longitude: +startLnglat.split(',')[0], latitude: +startLnglat.split(',')[1] },
          { longitude: +endLnglat.split(',')[0], latitude: +endLnglat.split(',')[1] },
        ];
        // 绘制起点终点规划路线
        this.$refs.TianMap2.drawStartAndEndHandle(lnglatList);
      }
    },
    // 绘制车辆此刻位置+起点终点规划路线
    async drawInitCar() {
      this.drawMarkerCar();
      this.drawStartAndEndLine();
      // 获取车辆行驶轨迹并绘制
      this.getCarInfoData(this.carDetail);
    },
    getCarInfoData(params) {
      this.isXie = false;
      let startTime;
      let endTime;
      // 1派单（接单） 5到装 7装货 8到卸 9卸货
      // 开始时间取上传装货磅单时间 7
      // 结束时间取卸货磅单时间 9 如果未完成卸货 则取此刻时间
      this.zhuangData = this.carDetail.carNodeVOList.filter((val) => +val.nodeType === 7);
      this.xieData = this.carDetail.carNodeVOList.filter((val) => +val.nodeType === 9);
      if (this.zhuangData.length) {
        startTime = +this.zhuangData[0].nodeTime;
      }
      if (this.xieData.length) {
        this.isXie = true;
        endTime = +this.xieData[0].nodeTime;
      }
      // 修改绘制轨迹规则  endTime 有卸货节点则取卸货节点时间 无使用当前时间
      if (!this.isXie) {
        endTime = new Date().getTime();
      }
      const diff = endTime - startTime;
      // 已经行驶的时间
      this.dialogMarkerData.driverTime = this.timeComputed(diff);

      // 行驶超过十分钟则调取轨迹接口
      if (diff > 10 * 60 * 1000) {
        // 压缩的方式获取
        this.getCarTrackDataNew(
          {
            a: params.truckNo,
            b: startTime, // cmd080 f节点 取派车时间
            c: endTime || new Date().getTime(), // 优先取卸货》卸货打卡》当前时间
          // d: 2,
          },
          {
            a: params.driverId,
            b: startTime, // cmd080 f节点 取派车时间
            c: endTime || new Date().getTime(), // 优先取卸货》卸货打卡》当前时间
            d: 2,
          },
          params.state,
        );
      }
    },
    // 获取轨迹集合进行轨迹绘制
    getCarTrackDataNew(data, data2, status) {
      let list;
      // axios
      //   .post("/demo/pass/carnumber/lgt?md=016&cmd=040", data, { responseType: "arraybuffer", headers: { user_id: 1001, userId: 1001 } })
      getCarTrackNew(data, { responseType: 'arraybuffer' })
        .then((response) => {
          if (response.data) {
            // 0 不压缩 1 解压
            // 处理数据
            const mapData = this.parseBetyData(response.data);
            // const mapData = {
            //   d: [
            //     { b: 118913544, c: 32167918, d: 0, e: 0, f: 214 },
            //     { b: 118915167, c: 32167104, d: 19, e: 0, f: 133 },
            //     { b: 118916587, c: 32162128, d: 24, e: 0, f: 187 },
            //     { b: 118916449, c: 32156860, d: 0, e: 0, f: 190 },
            //     { b: 118915497, c: 32152690, d: 26, e: 0, f: 191 },
            //     { b: 118914591, c: 32143158, d: 18, e: 0, f: 0 },
            //     { b: 118916710, c: 32146132, d: 0, e: 0, f: 40 },
            //     { b: 118915927, c: 32147118, d: 14, e: 0, f: 341 },
            //     { b: 118915909, c: 32147036, d: 0, e: 0, f: 173 },
            //     { b: 118916322, c: 32146713, d: 0, e: 0, f: 78 },
            //     { b: 118914156, c: 32142998, d: 38, e: 0, f: 221 },
            //     { b: 118313544, c: 32167918, d: 0, e: 0, f: 214 },
            //     { b: 118415167, c: 32167104, d: 19, e: 0, f: 133 },
            //     { b: 118516587, c: 32162128, d: 24, e: 0, f: 187 },
            //     { b: 118616449, c: 32156860, d: 0, e: 0, f: 190 },
            //     { b: 117154973, c: 32152690, d: 26, e: 0, f: 191 },
            //     { b: 118814591, c: 32143158, d: 18, e: 0, f: 0 },
            //     { b: 118656710, c: 32146132, d: 0, e: 0, f: 40 },
            //     { b: 118545927, c: 32147118, d: 14, e: 0, f: 341 },
            //     { b: 115615909, c: 32147036, d: 0, e: 0, f: 173 },
            //     { b: 113216322, c: 32146713, d: 0, e: 0, f: 78 },
            //     { b: 118567156, c: 32142998, d: 38, e: 0, f: 221 },
            //     { b: 118988544, c: 32167918, d: 0, e: 0, f: 214 },
            //     { b: 118885167, c: 32167104, d: 19, e: 0, f: 133 },
            //     { b: 118918887, c: 32162128, d: 24, e: 0, f: 187 },
            //     { b: 118915649, c: 32136860, d: 0, e: 0, f: 190 },
            //     { b: 118915497, c: 32122690, d: 26, e: 0, f: 191 },
            //     { b: 118914591, c: 32143158, d: 18, e: 0, f: 0 },
            //     { b: 118916710, c: 32142132, d: 0, e: 0, f: 40 },
            //     { b: 118915927, c: 32143118, d: 14, e: 0, f: 341 },
            //     { b: 118915909, c: 32142036, d: 0, e: 0, f: 173 },
            //     { b: 118916322, c: 32141713, d: 0, e: 0, f: 78 },
            //     { b: 118914156, c: 32142498, d: 38, e: 0, f: 221 },
            //   ],
            // };
            if (mapData.d && mapData.d.length !== 0) {
              list = mapData.d.map((val) => {
                // return {
                //   lnglat: `${val.b / 1000000},${val.c / 1000000}`, // 经纬度
                // };
                return {
                  longitude: val.b / 1000000,
                  latitude: val.c / 1000000,
                };
              });
              // console.log(list, 'list');
              // 轨迹不加装卸货点
              // this.lnglatList = [...list];
              // const list2 = [
              //   [116.478935, 39.997761],
              //   [116.478939, 39.997825],
              //   [116.478912, 39.998549],
              //   [116.478912, 39.998549],
              //   [116.478998, 39.998555],
              //   [116.478998, 39.998555],
              //   [116.479282, 39.99856],
              //   [116.479658, 39.998528],
              //   [116.480151, 39.998453],
              //   [116.480784, 39.998302],
              //   [116.480784, 39.998302],
              //   [116.481149, 39.998184],
              //   [116.481573, 39.997997],
              //   [116.481863, 39.997846],
              //   [116.482072, 39.997718],
              //   [116.482362, 39.997718],
              //   [116.483633, 39.998935],
              //   [116.48367, 39.998968],
              //   [116.484648, 39.999861],
              // ];
              const list3 = list.map(d => {
                return {
                  longitude: d[0],
                  latitude: d[1],
                };
              });
              this.$refs.TianMap2.drawPolyLine(list3);
              // // 116.478935, 39.997761
              // this.$refs.TianMap2.drawStartAndEndHandle2(list3);
            } else {
              // 第一个接口没数据 请求第二个接口
              // axios
              //   .post("/demo/pass/carnumber/lgt?md=016&cmd=042", data2, { responseType: "arraybuffer", headers: { user_id: 1001, userId: 1001 } })
              getCarTrackByIdNew(data2, { responseType: 'arraybuffer' })
                .then((response) => {
                  // console.log("%c  42轨迹 response: ", "font-size:20px;background-color: #4b4b4b;color:#fff;", response);
                  if (response.data) {
                    // 0 不压缩 1 解压
                    // 处理数据
                    const mapData = this.parseBetyData(response.data);
                    const data = mapData.d || [];
                    list = data.map((val) => {
                      // return {
                      //   lnglat: val.c, // 经纬度
                      //   createTime: val.e, // 上报时间
                      //   count: val.d, // 重复次数
                      // };
                      return {
                        longitude: val.b / 1000000,
                        latitude: val.c / 1000000,
                      };
                    });
                    this.$refs.TianMap2.drawPolyLine(list);
                  }
                })
                .catch((err) => err);
            }
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    },
    // 重载里程
    // loadCarLine(ring) {
    //   const line = AMap.GeometryUtil.distanceOfLine(ring);
    //   return line;
    // },

    parseBetyData(betyData) {
      // 0 不压缩 1 解压
      try {
        const dataBf = betyData.slice(1, betyData.byteLength); // 数据buff
        const typeBfView = new Int8Array(betyData, 0, 1);
        let jsonStr;
        if (typeBfView[0] === 0) {
          const byteArray = new Uint8Array(dataBf);
          jsonStr = new TextDecoder().decode(byteArray);
        } else {
          const data = pako.inflate(dataBf);
          jsonStr = new TextDecoder().decode(data);
        }
        return JSON.parse(jsonStr);
      } catch (e) {
        return [];
      }
    },
    getTimeH(distence, speed) {
      if (!distence || +speed === 0) {
        return '--';
      } else {
        const time = (distence / speed).toFixed(2);
        if (parseInt(time) === 0) {
          return '00';
        } else if (parseInt(time) > 0 && parseInt(time) < 10) {
          return '0' + parseInt(time);
        } else {
          return parseInt(time);
        }
      }
    },
    getTimeM(distence, speed) {
      if (!distence || +speed === 0) {
        return '--';
      } else {
        const time = (distence / speed).toFixed(2);
        const minute = Math.round((Number(time.substring(time.length - 2)) / 100) * 60);
        if (parseInt(minute) === 0) {
          return '00';
        } else if (parseInt(minute) > 0 && parseInt(minute) < 10) {
          return '0' + parseInt(minute);
        } else {
          return parseInt(minute);
        }
      }
    },
    timeComputed(timeDiff) {
      if (timeDiff) {
        // const seconds = Math.floor((timeDiff / 1000) % 60); // 计算秒
        let minutes = Math.floor((timeDiff / (1000 * 60)) % 60); // 计算分钟
        let hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24); // 计算小时
        let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // 计算天
        days = days ? (days + 'd') : '';
        hours = hours ? (hours + 'h') : '';
        minutes = minutes ? (minutes + 'm') : '';
        return days + hours + minutes;
      } else {
        return '--';
      }
    },
    removeMarkAndPolyLine() {
      this.$refs.TianMap2.removeMarkAndPolyLine();
    },
  },
};
</script>
<style lang="scss" scoped>
.fullMaps {
  // position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.c-tool {
  color: #c0c4cc;
  font-size: 16px;
  i {
    margin-right: 10px;
    cursor: pointer;
  }
}
.load-icon {
  font-size: 24px;
}
.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #dcdfe6;
  margin-right: 10px;
}
.circleactive {
  background: #4385ff;
  // margin-right: 10px;
}
.map {
  width: 100%;
  height: 360px;
  max-height: 100%;
}
.map-full {
  color: #4385ff;
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 50px;
  z-index: 1;
  cursor: pointer;
}
.fullMap {
  position: relative;
  width: 100%;
  // height: 610px;
  // height:100vh;
  padding: 0;
  margin: 0;
}
.w-dui {
  font-size: 15px;
  color: #c0c4cc;
}
.bg-more {
  &.bg {
    background-image: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 30%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.6) 70%, rgb(255, 255, 255, 1) 100%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.6) 70%, rgb(255, 255, 255, 1) 100%);
  }

  position: absolute;
  left: 0;
  bottom: 0;
  max-height: 100%;
  width: 100%;
  height: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 16px;
  color: #4385ff;
  span {
    cursor: pointer;
  }
}
.my-panel-body {
  position: relative;
  overflow: hidden;
}
.full-img {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.all-full {
  top: -44px;
  right: 40px;
  &:hover {
    opacity: 0.9;
  }
}
.no-car {
  display: flex;
  justify-content: center;
  padding: 10px;
  color: #666;
}
.item-show {
  position: absolute;
  right: 20px;
  top: 11.11vh;
  padding: 20px;
  overflow-y: hidden;
  width: 300px;
  background: white;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0 0 4px 4px;
  .w-line-content {
    height: calc(100vh - 480px);
    max-height: 300px;
    overflow-y: scroll;
  }
  .load-content {
    width: 272px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #04122b;
  }
  .w-content {
    display: inline-block !important;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5a6073;
  }
  .w-item2 {
    display: inline-block !important;
    margin-left: 15px;
    font-size: 15px !important;
    font-weight: bold;
  }
  .w-item3 {
    display: inline-block !important;
    margin-left: 20px;
    font-size: 15px !important;
    font-weight: bold;
  }
  .w-line {
    width: 100%;
    height: 1px;
    background: #d8d8d8;
    margin: 10px 0;
  }
  .w-item {
    display: block;
    height: 20px;
    line-height: 20px;
    color: #5a6073;
    font-size: 12px;

    &.w-title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      color: #04122b;
      // height: 45px;
      // line-height: 45px;
      margin-bottom: 5px;
    }
    &.w-blue {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4385ff;
    }
  }
  .number-wrap {
    > div {
      &:last-child {
        margin-left: 24px;
      }
    }
  }
  .driver-list {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    > div {
      i {
        font-size: 20px;
        margin-right: 4px;
      }
      &:first-child {
        margin-right: 52px;
      }
    }
  }
  .s-line {
    width: 1px;
    height: 35px;
    border: 1px solid #10ce66;
  }
  .s-line-h {
    width: 1px;
    height: 35px;
    border: 1px solid #c0c4cc;
  }
  .w-line-show {
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    .el-col-3 {
      text-align: center;
    }
    span {
      color: #272727;
      &.w-time {
        color: #b0b3bd;
      }
    }
  }
}
</style>
